import { FunctionalComponent, h, Fragment } from "preact";
import { styled } from "linaria/react";
import Button from "components/shared/Button";
import Colors from "style/colors";
import useSubscriptionGroup from "hooks/subscriptionGroupHooks";
import mq from "style/breakpoints";
import { useEffect, useState } from "react";
import { validateEmail } from "util/validationUtil";
import Input from "components/shared/Input";
import { DisplayOverlay } from "style/layout";
import Icon, { IconTypes } from "components/shared/Icon";
import { RenderLogo } from "components/layout/RenderLogo";
import { useRef } from "preact/hooks";
import { downloadCSV, transfCSV } from "util/team";
import { RenderIcon } from "components/layout/RenderIcon";

const Container = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 10005;
  width: 343px;
  height: auto;
  max-height: max-content;
  background: ${Colors.bgLight2};
  margin: auto;
  border-radius: 16px;

  ${mq.minSm} {
    width: 636px;
  }
`;

const InvitationSentContainer = styled(Container)`
  > div {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;

    ${mq.minSm} {
      padding: 40px;
    }

    h1 {
      color: #000000;
      font-size: 28px;
      font-weight: 700;
      line-height: 36px;
      text-align: center;
    }

    p {
      color: #444444;
      text-align: center;
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
    }

    > button {
      width: 100%;
      height: 56px;
      border-radius: 9999px;
      span {
        color: #ffffff;
        font-size: 18px;
        font-weight: 600;
        line-height: 24px;
      }
    }
  }
`;

const InviteMembersContent = styled.div`
  padding: 0;
  width: 100%;
  padding: 40px;

  h1 {
    color: #000000;
    font-family: "Neusa Next Pro";
    font-size: 28px;
    font-weight: 700;
    line-height: 36px;
    margin-bottom: 40px;
  }

  > div > button {
    width: 100%;
    height: 56px;
    background: ${Colors.bgDark0};
    border-radius: 9999px;
    border: none;
    cursor: pointer;
    display: inline-flex;

    > div > span {
      color: #ffffff;
      font-size: 18px;
      font-weight: 600;
      line-height: 24px;
    }

    :hover {
      opacity: 0.9;
    }
  }
`;

const AddEmailContainer = styled.div`
  > form {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 14px;
    margin-bottom: 40px;

    .input-email {
      width: 100%;

      input {
        color: #000000;
        font-size: 18px;
        font-weight: 500;
        line-height: 28px;
        padding: 14px 16px;
      }
      .error {
        position: absolute;
        top: 56px;
        color: #e11900;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
      }

      label {
        display: none !important;
      }
    }

    > button {
      width: 99px;
      height: 56px;
      background: ${Colors.bgLight2};
      border-radius: 9999px;
      border: 1px solid #000000;

      :hover:active {
        background: ${Colors.bgLight2};
      }

      span {
        color: #000000;
        font-size: 18px;
        font-weight: 600;
        line-height: 24px;
      }
    }
  }
`;

const Close = styled.div`
  position: absolute;
  right: 24px;
  top: 24px;
  cursor: pointer;

  span {
    font-weight: 600;
  }
`;

const MembersInvite = styled.div`
  border-radius: 8px;
  border: 1px solid #8e8e93;
  padding: 16px 40px 16px 20px;
  margin-bottom: 40px;
  max-height: 45vh;
  overflow-y: auto;
  overflow-x: hidden;

  @-moz-document url-prefix() {
    scrollbar-width: none;
  }

  ::-webkit-scrollbar {
    width: 6px;
    background: transparent;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: #333333;
    border-radius: 6px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #333333;
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    span {
      color: #000000;
      font-size: 18px;
      font-weight: 600;
      line-height: 24px;
      opacity: 0.34;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    > button {
      background: transparent;

      :hover:active {
        background: transparent;
      }
    }
  }
`;

const Divider = styled.div`
  width: 100%;
  height: 1px !important;
  border-top: 1px dashed ${Colors.bgGrey2} !important;
  margin: 16px 0 !important;
  padding: 0 !important;
`;

const UploadCSVContainer = styled(Container)`
  h1 {
    color: #000000;
    font-family: "Neusa Next Pro";
    font-size: 28px;
    font-weight: 700;
    line-height: 36px;
    margin-bottom: 40px;
  }
`;

const UploadCSVContent = styled.div<{ error: boolean }>`
  > div {
    padding: 40px;
    height: 100%;
    padding: 40px;

    .upload-content {
      position: relative;
      height: 200px;
      border-radius: 8px;
      border: 1px solid #8e8e93;
      margin-bottom: 24px;
    }

    .drop-zone {
      text-align: center;
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      p {
        color: #000000;
        text-align: center;
        font-size: 18px;
        font-weight: 600;
        line-height: 24px;
        opacity: 0.34;
      }
    }

    > button {
      width: 100%;
      height: 56px;
      background: ${Colors.bgDark0};
      border-radius: 9999px;
      border: none;
      cursor: pointer;
      display: inline-flex;

      > div > span {
        color: #ffffff;
        font-size: 18px;
        font-weight: 600;
        line-height: 24px;
      }

      :hover {
        opacity: 0.9;
      }
    }
  }
`;

const ButtonsUploadCSV = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;

  > div {
    display: flex;
    align-items: center;
    background: transparent;
    padding: 0;
    color: #000000;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    cursor: pointer;

    svg {
      margin-right: 12px;
    }
  }
`;

interface OrganizationProps {
  onClose?: () => void;
  type?: string;
  setRefetch?: () => void;
  setMemberModalContent?: (type: string) => void;
  memberLimit: number;
  onDelete?: () => void;
  isLoading?: boolean;
}

const MemberModalContent: FunctionalComponent<OrganizationProps> = ({
  onClose,
  type,
  setRefetch,
  setMemberModalContent,
  memberLimit,
  onDelete,
  isLoading,
}: OrganizationProps) => {
  const { X } = IconTypes;
  const { getSubscriptionGroupInvites } = useSubscriptionGroup();
  const subscriptionGroupInvites = getSubscriptionGroupInvites();
  const { createSubscriptionGroupInvite } = useSubscriptionGroup();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [memberList, setMemberList] = useState<string[]>([]);
  const [emailList, setEmailList] = useState<string[]>([]);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [isVisibleModalInvitationSent, setIsVisibleModalInvitationSent] =
    useState(false);
  const [fileName, setFileName] = useState<string[]>([]);
  const [emailListUpload, setEmailListUpload] = useState<any>([]);
  const [returnModal, setReturnModal] = useState("");
  const inviteSentModal = type === "invite-sent-modal";
  const deleteModal = type === "delete-modal";
  const limitMemberModal = type === "limit-member-modal";
  const uploadCSVModal = type === "upload-csv-modal";

  const preventDuplicationMemberList = memberList.filter(
    (value, index, arr) => arr.indexOf(value) === index
  );

  const preventDuplicationListUpload = emailListUpload
    .filter((value, index, arr) => arr.indexOf(value) === index)
    .filter((email) => !preventDuplicationMemberList?.includes(email));

  const sendInvite = async () => {
    setLoading(true);

    if (
      !!emailList?.length &&
      emailList?.length + preventDuplicationMemberList?.length > memberLimit
    ) {
      setMemberModalContent?.("limit-member-modal");
      setReturnModal("member-modal");
      setLoading(false);
      return;
    }

    if (
      !!preventDuplicationListUpload?.length &&
      preventDuplicationListUpload?.length +
        preventDuplicationMemberList?.length >
        memberLimit
    ) {
      setMemberModalContent?.("limit-member-modal");
      setReturnModal("upload-csv-modal");
      setLoading(false);
      return;
    }

    if (
      !!emailList?.length &&
      emailList?.length + memberList?.length > memberLimit
    ) {
      setMemberModalContent?.("limit-member-modal");
      setReturnModal("member-modal");
      setLoading(false);
      return;
    }

    if (
      !!emailListUpload?.length &&
      emailListUpload?.length + memberList?.length > memberLimit
    ) {
      setMemberModalContent?.("limit-member-modal");
      setReturnModal("upload-csv-modal");
      setLoading(false);
      return;
    }

    const result = await createSubscriptionGroupInvite({
      emails: !!preventDuplicationListUpload?.length
        ? preventDuplicationListUpload
        : emailList,
    });

    if (result) {
      setLoading(false);
      setIsVisibleModalInvitationSent(true);
      setEmailListUpload([]);
      setTimeout(() => {
        setRefetch?.();
      }, 1000);
    } else {
      setLoading(false);
      setError(true);
    }
  };

  const openIntercomChat = (e) => {
    e.preventDefault();
    if (typeof window !== "undefined" && window.Intercom) {
      window.Intercom("show");
      if (!!emailList?.length && returnModal === "member-modal") {
        setMemberModalContent?.(returnModal);
        setReturnModal("");
        setEmailListUpload([]);
      } else if (
        !!preventDuplicationListUpload?.length &&
        returnModal === "upload-csv-modal"
      ) {
        setMemberModalContent?.(returnModal);
        setReturnModal("");
        setEmailList([]);
      } else {
        setEmailListUpload([]);
        onClose?.();
        setEmailList([]);
      }
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    if (files.length > 0) {
      setFileName([...fileName, files[0]?.name || "file"]);
      const reader = new FileReader();
      reader.onload = (event) => {
        const text = event?.target?.result;
        if (typeof text === "string") {
          const data = transfCSV(text);
          const list: string[] = [];
          data?.map((d) => !!d.email?.length && list.push(d.email));
          setEmailListUpload([...emailListUpload, ...list]);
        }
      };
      reader.readAsText(files[0]);
    }
  };

  const fileInputRef = useRef<any>(null);

  const handleFileChange = (event) => {
    const file = event.target.files?.[0];
    if (file) {
      if (fileName?.includes(file.name)) {
        return;
      }

      setFileName([...fileName, file.name]);
      const reader = new FileReader();
      reader.onload = (e) => {
        const text = e?.target?.result;
        if (typeof text === "string") {
          const data = transfCSV(text);
          const list: string[] = [];
          data?.map((d) => !!d.email?.length && list.push(d.email));
          setEmailListUpload([...emailListUpload, ...list]);
        }
      };
      reader.readAsText(file);
    }
  };

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const addEmail = (e: h.JSX.TargetedEvent<HTMLFormElement, Event>) => {
    e.preventDefault();

    const existingEmailInList = emailList.some(
      (addedEmail) => addedEmail.toLowerCase() === email.toLowerCase()
    );
    const existingEmailInMemberList = preventDuplicationMemberList.some(
      (memberEmail) => memberEmail.toLowerCase() === email.toLowerCase()
    );

    if (!validateEmail(email)) {
      setError(true);
      setEmailError("Please enter a valid email address.");
      return;
    }

    if (existingEmailInList || existingEmailInMemberList) {
      setError(true);
      setEmailError(`${email} has already been added.`);
      return;
    }

    setEmailList([...emailList, email]);
    setEmail("");
    setError(false);
    setEmailError("");
  };

  useEffect(() => {
    subscriptionGroupInvites &&
      subscriptionGroupInvites?.members?.data?.map((member) =>
        setMemberList((prevState) => [...prevState, member.email])
      );
  }, [subscriptionGroupInvites]);

  return (
    <Fragment>
      {isVisibleModalInvitationSent ||
      inviteSentModal ||
      deleteModal ||
      limitMemberModal ? (
        <InvitationSentContainer>
          <div>
            {RenderLogo("white-background-sm")}
            <h1>
              {limitMemberModal
                ? "Oops It looks like you’ve hit the limit"
                : deleteModal
                ? "Are you sure you want to Delete this User?"
                : "Invite Sent"}
            </h1>
            <p>
              {deleteModal
                ? "This member will no longer have access to pliability."
                : limitMemberModal
                ? "If you want to add more members you are going to need a custom plan. Our team is happy to help you out."
                : "Your members will receive an email shortly to join."}
            </p>
            <Button
              size="S"
              text={
                deleteModal
                  ? "Delete"
                  : limitMemberModal
                  ? "Contact Our Support Team"
                  : "Done"
              }
              handleClick={(e) => {
                if (deleteModal) {
                  onDelete?.();
                } else if (limitMemberModal) {
                  openIntercomChat(e);
                } else {
                  onClose?.();
                }
              }}
              loading={isLoading}
            />
          </div>
        </InvitationSentContainer>
      ) : uploadCSVModal ? (
        <UploadCSVContainer>
          <UploadCSVContent error={error}>
            <div>
              <h1>Invite your team members</h1>
              <Close
                onClick={() => {
                  setEmailListUpload([]);
                  setEmailList([]);
                  onClose?.();
                }}
              >
                <Icon name={X} />
              </Close>
              {!emailListUpload?.length ? (
                <div
                  className="upload-content"
                  onClick={(e) => e.stopPropagation()}
                >
                  <div
                    className="drop-zone"
                    onDragOver={handleDragOver}
                    onDrop={handleDrop}
                  >
                    <p>
                      Drop files here
                      <br /> or use upload button.
                    </p>
                  </div>
                </div>
              ) : (
                <Fragment>
                  <MembersInvite>
                    {fileName?.map((name, index) => (
                      <Fragment key={index}>
                        <div>
                          <span>{name}</span>
                          <Button
                            size="S"
                            text="Remove"
                            handleClick={() => {
                              const filterFileName = fileName?.filter(
                                (na) => na !== name
                              );
                              setFileName(filterFileName);
                              !filterFileName?.length && setEmailListUpload([]);
                            }}
                          />
                        </div>
                        {fileName?.length > 0 &&
                          index !== fileName?.length - 1 && <Divider />}
                      </Fragment>
                    ))}
                  </MembersInvite>
                </Fragment>
              )}
              <div>
                <input
                  ref={fileInputRef}
                  type="file"
                  accept=".csv"
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                />
                <ButtonsUploadCSV>
                  <div onClick={handleButtonClick}>
                    {RenderIcon("upload")}
                    Upload a CSV File
                  </div>
                  <div
                    onClick={() => {
                      downloadCSV(
                        [
                          { email: "test@test.com" },
                          { email: "test@test.com" },
                        ],
                        "template"
                      );
                    }}
                  >
                    Download Template
                  </div>
                </ButtonsUploadCSV>
              </div>
              <Button
                size="S"
                text="Invite members"
                handleClick={sendInvite}
                loading={loading}
                disabled={!emailListUpload?.length}
                unwrapped={true}
              />
            </div>
          </UploadCSVContent>
        </UploadCSVContainer>
      ) : (
        <Container>
          <Close
            onClick={() => {
              onClose?.();
              setEmailListUpload([]);
              setEmailList([]);
            }}
          >
            <Icon name={X} />
          </Close>
          <InviteMembersContent>
            <h1>Invite your team members</h1>
            <AddEmailContainer>
              <form onSubmit={addEmail}>
                <Input
                  id="email"
                  label="Email"
                  type="text"
                  value={email}
                  error={emailError}
                  autocomplete="off"
                  onChange={(e) => {
                    setEmail((e?.target as HTMLInputElement)?.value);
                    setError(false);
                    setEmailError("");
                  }}
                  placeholder="Invite your team by email"
                  className="input-email"
                />
                <Button size="S" text="Add" />
              </form>
            </AddEmailContainer>
            {!!emailList?.length && (
              <MembersInvite>
                {emailList?.map((email, index) => (
                  <Fragment key={index}>
                    <div>
                      <span>{email}</span>
                      <Button
                        size="S"
                        text="Remove"
                        handleClick={() => {
                          const filterEmail = emailList?.filter(
                            (em) => em !== email
                          );
                          setEmailList(filterEmail);
                        }}
                      />
                    </div>
                    {emailList?.length > 0 &&
                      index !== emailList?.length - 1 && <Divider />}
                  </Fragment>
                ))}
              </MembersInvite>
            )}
            <div style={{ width: "100%" }}>
              <Button
                size="S"
                text="Invite members"
                handleClick={sendInvite}
                loading={loading}
                disabled={!emailList?.length}
                unwrapped={true}
              />
            </div>
          </InviteMembersContent>
        </Container>
      )}
      <DisplayOverlay
        className="overlay"
        background="#000000"
        style={{ opacity: 0.7, zIndex: 10004 }}
        onClick={() => {
          if (!!emailList?.length && returnModal === "member-modal") {
            setMemberModalContent?.(returnModal);
            setReturnModal("");
            setEmailListUpload([]);
          } else if (
            !!emailListUpload?.length &&
            returnModal === "upload-csv-modal"
          ) {
            setMemberModalContent?.(returnModal);
            setReturnModal("");
            setEmailList([]);
          } else {
            setEmailListUpload([]);
            onClose?.();
            setEmailList([]);
          }
        }}
      />
    </Fragment>
  );
};

export default MemberModalContent;
