import { Fragment, FunctionalComponent, h } from "preact";
import { styled } from "linaria/react";
import mq from "style/breakpoints";
import Colors from "style/colors";
import { useEffect, useState } from "preact/hooks";
import useSubscriptionGroup from "hooks/subscriptionGroupHooks";
import MoreMenu from "components/shared/MoreMenu";
import { useWindowSize } from "hooks/windowHook";
import { AccountType } from "models/subscription";
import dayjs from "dayjs";
import MemberModalContent from "components/settings/MemberModalContent";
import SearchInput from "components/team/SearchInput";
import Icon, { IconTypes } from "components/shared/Icon";
import { ModalBulkActionType, ModalModalType } from "models/team";
import SearchDropdown from "components/team/SearchDropdown";
import BulkActionBarModal from "./BulkActionBarModal";

const Container = styled.div`
  .overlay {
    z-index: 10000;
  }
`;

const TableContainer = styled.div`
  border-radius: 16px;

  > span {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: ${Colors.txtGrey3};
  }
`;

const Table = styled.table<{ width: number }>`
  width: 100%;
  border-spacing: 0;
  margin-bottom: 80px;
  margin-top: 16px;
  border-radius: 16px;
  border: 1px solid ${Colors.bgGrey2};
`;

const Thead = styled.thead`
  border-radius: 16px;
  border-bottom: 1px solid ${Colors.bgGrey2};

  > tr {
    height: 54px;
    border-radius: 16px;

    > th {
      font-weight: 700;
      text-align: initial;
      border-bottom: 1px solid ${Colors.bgGrey2};
      position: relative;

      > span {
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        color: ${Colors.txtDark};
        text-transform: initial;
      }
    }
    > th:first-child {
      padding-left: 24px;
    }
  }
`;

const Tbody = styled.tbody<{ width: number }>`
  tr {
    padding-left: 28px;
    background: #ffffff;
    border: 1px solid ${Colors.bgGrey2};
    text-transform: capitalize;

    > td {
      padding: 16px 0;
      border-bottom: 1px solid ${Colors.bgGrey2};
      position: relative;

      > span {
        color: #8e8e93;
        font-weight: 600;
        font-size: ${({ width }) => (width < 375 ? "12px" : "14px")};
        line-height: 16px;
        text-align: initial;
        text-transform: capitalize;
      }
      max-width: 180px;
    }

    > td:first-child {
      padding-left: 24px;

      ${mq.minXs} {
        padding-right: 28px !important;
      }
    }
  }
  > tr:last-child {
    > td {
      border-bottom: none;
    }
    > td:nth-child(1) {
      border-bottom: none;
      border-radius: 0 0 0 16px;
    }

    > td:nth-child(5) {
      border-bottom: none;
      border-radius: 0 0 16px 0;
    }
  }
`;

const MoreMenuContainer = styled.div`
  .more-menu {
    > span:before {
      color: ${Colors.txtGrey3};
    }
  }
  .menu {
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.4);
    border-radius: 8px;
    background: ${Colors.bgDark4};
    right: -14px !important;
    left: auto !important;
    bottom: unset !important;
    top: 30px !important;

    > div {
      padding: 10px 12px;
      border-radius: 0;
      > span {
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
      }
    }

    > div:nth-child(2) {
      border-top: 1px solid ${Colors.bgGrey1};
    }
  }
`;

const NoContent = styled.div`
  display: flex;
  justify-content: center;
  padding: 144px 0;

  > p {
    color: ${Colors.txtDark};
    font-weight: normal;
    line-height: 140%;
    font-size: 16px;
  }
`;

const StatusMember = styled.div<{ pending: boolean }>`
  padding: 2px 6px;
  border-radius: 2px;
  border: ${({ pending }) =>
    pending ? "1px solid #FFC043" : "1px solid #048848"};
  color: ${({ pending }) => (pending ? "#FFC043" : "#048848")};
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  text-transform: capitalize;
  width: max-content;
`;

const ContainerConfirmModal = styled.div`
  > div:nth-child(1) {
    height: 200px;
    z-index: 10001;

    > div:nth-child(1) {
      span {
        font-weight: 600;
        line-height: 36px;
        color: ${Colors.txtDark1};
        font-size: 18px;
        margin-bottom: 8px;
      }
    }
    > div:nth-child(2) {
      > div {
        border-radius: 8px;
        height: 56px;
        span {
          line-height: 36px;
          font-weight: 600 !important;
        }
      }
    }
  }
  > div:nth-child(2) {
    opacity: 0.7;
    background: ${Colors.bgDark0};
  }
`;

const SearchBarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  background: ${Colors.bgLight2};
  border-radius: 16px;
  margin-top: 32px;
`;

const DropdownContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const BulkActionBar = styled.div`
  position: fixed;
  max-width: max-content;
  margin-bottom: 0;
  background-color: ${Colors.bgDark4};
  left: 0;
  right: 0;
  box-shadow: 0px 16px 48px 0px rgba(0, 0, 0, 0.22);
  border-radius: 8px;
  margin: 0 auto;
  bottom: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 68px;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.14);
  gap: 31px;
  padding: 0 24px;
`;

const MembersSelected = styled.div`
  color: ${Colors.txtLight};
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  display: flex;
  align-items: center;
`;

const ResendInvite = styled.div`
  color: ${Colors.txtGrey3};
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;

  :hover {
    border-radius: 4px;
    background: ${Colors.bgGrey1};
    color: ${Colors.txtLight};
  }
`;

const Deactivate = styled.div`
  color: ${Colors.txtGrey3};
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;

  :hover {
    border-radius: 4px;
    background: ${Colors.bgGrey1};
    color: ${Colors.txtLight};
  }
`;

const Close = styled.div`
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  background: ${Colors.bgLight2};
  display: flex;
  align-items: center;

  .icon-X {
    font-size: 7px;
    font-weight: 600;
    cursor: pointer;
  }
`;

const AdminTag = styled.div`
  border-radius: 99px;
  border: 1px solid #0d41e1;
  padding: 2px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #0d41e1;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  width: 51px;
  margin-left: 16px;
`;

const InputCheckContainer = styled.div<{ disabled?: boolean }>`
  width: 20px;
  height: 20px !important;
  display: flex !important;
  align-items: center;
  margin-right: 8px;

  input {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }
  .checkbox {
    position: absolute;
    width: 14px !important;
    height: 14px !important;
    box-sizing: content-box;
    border-radius: 2px;

    .icon-Success {
      font-size: 14px;
      :before {
        color: ${Colors.txtDark};
      }
    }
  }

  > label {
    position: relative !important;
    left: 0 !important;
    padding: 0 !important;
    cursor: ${({ disabled }) => (disabled ? "initial" : "pointer")} !important;
    display: flex !important;
    align-items: center !important;
    > p {
      width: max-content;
      margin-left: 28px;
      margin-top: 1.5px;
      font-size: 14px;
      line-height: 16px;
    }
  }
`;

interface MemberListTableProps {
  invitationModal: boolean;
  setInvitationModal: (status: boolean) => void;
  subscriptionGroupInvites: any;
  setRefetch: () => void;
  isLimitMember?: boolean;
  uploadCSV?: boolean;
  onClose?: () => void;
  memberLimit: number;
}

const MemberListTable: FunctionalComponent<MemberListTableProps> = ({
  invitationModal,
  setInvitationModal,
  subscriptionGroupInvites,
  setRefetch,
  isLimitMember,
  onClose,
  uploadCSV,
  memberLimit,
}: MemberListTableProps): JSX.Element | null => {
  const { X } = IconTypes;
  const { width } = useWindowSize();
  const {
    deleteSubscriptionGroupInvite,
    resendSubscriptionGroupInvite,
    manageAdminSubscriptionGroups,
  } = useSubscriptionGroup();
  const [orderedMemberList, setOrderedMemberList] = useState<any>([]);
  const [memberModalContent, setMemberModalContent] = useState("");
  const [searchText, setSearchText] = useState("");
  const [statusFilter, setStatusFilter] = useState("All");
  const [roleFilter, setRoleFilter] = useState("All");
  const [isChecked, setIsChecked] = useState(false);
  const [isVisibleBulkActionBar, setIsVisibleBulkActionBar] = useState<
    string | null
  >("bulk-action-bar");
  const [deleteId, setDeleteId] = useState<number | null>(null);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [selectedMembers, setSelectedMembers] = useState<any>([]);
  const [selectAll, setSelectAll] = useState(false);

  const statusOptions = [
    { value: "All", label: "All" },
    { value: "Active", label: "Active" },
    { value: "Pending", label: "Pending" },
  ];

  const roleOptions = [
    { value: "All", label: "All" },
    { value: "Admin", label: "Admin" },
    { value: "Member", label: "Member" },
  ];

  // Combined filtering function
  const filterMembers = () => {
    let filteredMembers = subscriptionGroupInvites?.members?.data || [];

    if (searchText && searchText.length > 0) {
      filteredMembers = filteredMembers.filter((member) => {
        const emailMatch = member.email
          ?.toLowerCase()
          .includes(searchText.toLowerCase());
        const firstNameMatch = member.user?.firstName
          ?.toLowerCase()
          .includes(searchText.toLowerCase());
        const lastNameMatch = member.user?.lastName
          ?.toLowerCase()
          .includes(searchText.toLowerCase());

        return emailMatch || firstNameMatch || lastNameMatch;
      });
    }

    if (statusFilter === "All") {
      // Do nothing, include all members
    } else if (statusFilter === "Active") {
      filteredMembers = filteredMembers.filter(
        (member) => member.status === "active"
      );
    } else if (statusFilter === "Pending") {
      filteredMembers = filteredMembers.filter(
        (member) => member.status === "invite_pending"
      );
    }

    if (roleFilter === "All") {
      // Do nothing, include all roles
    } else if (roleFilter === "Admin") {
      filteredMembers = filteredMembers.filter(
        (member) =>
          member.user?.subscriptionGroupRole === "sub_group_admin" ||
          member.user?.subscriptionGroupRole === "sub_group_owner"
      );
    } else if (roleFilter === "Member") {
      filteredMembers = filteredMembers.filter(
        (member) => member.user?.subscriptionGroupRole === "sub_group_member"
      );
    }

    const sortedMemberList = filteredMembers?.sort((a, b) => {
      const getOrder = (role) => {
        if (role === "sub_group_owner") return 1;
        if (role === "sub_group_admin") return 2;
        return 3;
      };

      return (
        getOrder(a?.user?.subscriptionGroupRole) -
        getOrder(b?.user?.subscriptionGroupRole)
      );
    });

    setOrderedMemberList(sortedMemberList);
  };

  useEffect(() => {
    filterMembers();
  }, [
    searchText,
    statusFilter,
    roleFilter,
    isChecked,
    subscriptionGroupInvites,
  ]);

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };

  const handleClearSearch = () => {
    setSearchText("");
  };

  const handleStatusFilterChange = (e) => {
    setStatusFilter(e.target.value);
  };

  const handleRoleFilterChange = (e) => {
    setRoleFilter(e.target.value);
  };

  const deleteMember = async (memberId) => {
    setIsLoadingDelete(true);

    try {
      await deleteSubscriptionGroupInvite({ inviteIds: [memberId] });
      filterMembers();
      setTimeout(() => {
        setRefetch?.();
      }, 1000);
      setMemberModalContent("");
      setInvitationModal(false);
      onClose?.();
      setIsLoadingDelete(false);
    } catch {
      setIsLoadingDelete(false);
    }
  };

  const resendInvite = async (memberId) => {
    try {
      await resendSubscriptionGroupInvite({ inviteIds: [memberId] });
      setMemberModalContent("invite-sent-modal");
    } catch {}
  };

  const assignAdmin = async (memberId, admin) => {
    try {
      await manageAdminSubscriptionGroups({
        memberId,
        actionType: admin ? "demote" : "promote",
      });
      setRefetch();
    } catch {}
  };

  const isSearchActive =
    (searchText && searchText.length > 0) ||
    roleFilter !== "All" ||
    statusFilter !== "All";

  const hasMembers = subscriptionGroupInvites?.members?.data?.length > 0;

  useEffect(() => {
    invitationModal && setMemberModalContent(ModalModalType.MemberModal);
    isLimitMember && setMemberModalContent(ModalModalType.LimitMemberModal);
    uploadCSV && setMemberModalContent(ModalModalType.UploadCsvModal);
  }, [invitationModal, isLimitMember, uploadCSV, orderedMemberList]);

  return (
    <Fragment>
      <SearchBarContainer>
        <SearchInput
          value={searchText}
          onChange={handleSearchChange}
          onClear={handleClearSearch}
          placeholder="Search Members..."
        />
        <DropdownContainer>
          <SearchDropdown
            label="Status"
            value={statusFilter}
            onChange={handleStatusFilterChange}
            options={statusOptions}
          />
          <SearchDropdown
            label="Account Type"
            value={roleFilter}
            onChange={handleRoleFilterChange}
            options={roleOptions}
          />
        </DropdownContainer>
      </SearchBarContainer>
      <Container>
        {!hasMembers ? (
          <NoContent>
            <p>No members available.</p>
          </NoContent>
        ) : (
          <TableContainer>
            <Table width={width}>
              <Thead>
                <tr>
                  <th>
                    <span style={{ display: "flex", alignItems: "center" }}>
                      <InputCheckContainer>
                        <label id="checkbox">
                          <input
                            type="checkbox"
                            onChange={() => {
                              setIsVisibleBulkActionBar(
                                ModalBulkActionType.BulkActionBar
                              );
                              if (selectAll) {
                                setSelectAll(false);
                                setSelectedMembers([]);
                              } else {
                                setSelectAll(true);
                                setSelectedMembers(
                                  subscriptionGroupInvites?.members?.data?.filter(
                                    (member) =>
                                      member?.user?.subscriptionGroupRole !==
                                      "sub_group_owner"
                                  )
                                );
                              }
                            }}
                          />
                          <span
                            htmlFor="checkbox"
                            className={"checkbox"}
                            style={{ border: "1px solid #000000" }}
                          >
                            {selectAll && (
                              <Icon size="M" name={IconTypes.Success} />
                            )}
                          </span>
                        </label>
                      </InputCheckContainer>
                      Full Name
                    </span>
                  </th>
                  <th>
                    <span>Email</span>
                  </th>
                  <th>
                    <span>Status</span>
                  </th>
                  <th>
                    <span>Member Since</span>
                  </th>
                  <th style={{ width: "auto", padding: "0" }}></th>
                </tr>
              </Thead>
              <Tbody width={width}>
                {!!orderedMemberList?.length ? (
                  orderedMemberList?.map((member, i) => {
                    const status = member?.status;
                    const subscriptionGroupRole =
                      member?.user?.subscriptionGroupRole;
                    return (
                      <tr key={i}>
                        <td>
                          <span
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <InputCheckContainer
                              disabled={
                                subscriptionGroupRole === "sub_group_owner"
                              }
                            >
                              <label id="checkbox">
                                <input
                                  type="checkbox"
                                  style={{ marginRight: "8px" }}
                                  disabled={
                                    subscriptionGroupRole === "sub_group_owner"
                                  }
                                  onChange={() => {
                                    setIsVisibleBulkActionBar(
                                      ModalBulkActionType.BulkActionBar
                                    );
                                    if (
                                      !selectedMembers.filter(
                                        (check) => check?.id === member.id
                                      )?.length
                                    ) {
                                      const mergeMembers = [
                                        ...selectedMembers,
                                        member,
                                      ];

                                      const filterSubscriptionGroupInvites =
                                        subscriptionGroupInvites?.members?.data?.filter(
                                          (member) =>
                                            member?.user
                                              ?.subscriptionGroupRole !==
                                            "sub_group_owner"
                                        );

                                      if (
                                        mergeMembers?.length ===
                                        filterSubscriptionGroupInvites?.length
                                      ) {
                                        setSelectAll(true);
                                      }

                                      setSelectedMembers([
                                        ...selectedMembers,
                                        member,
                                      ]);
                                    } else {
                                      setSelectAll(false);
                                      const filterCheck =
                                        selectedMembers?.filter(
                                          (select) => select.id !== member.id
                                        );
                                      setSelectedMembers(filterCheck);
                                    }
                                  }}
                                />
                                <span
                                  htmlFor="checkbox"
                                  className={"checkbox"}
                                  style={{
                                    border:
                                      subscriptionGroupRole ===
                                      "sub_group_owner"
                                        ? "none"
                                        : "1px solid #d1d1d6",
                                  }}
                                >
                                  {!!selectedMembers.filter(
                                    (check) => check.id === member.id
                                  )?.length &&
                                    subscriptionGroupRole !==
                                      "sub_group_owner" && (
                                      <Icon size="M" name={IconTypes.Success} />
                                    )}
                                </span>
                              </label>
                            </InputCheckContainer>
                            {member?.user?.firstName || "--"}{" "}
                            {member?.user?.lastName}
                            {subscriptionGroupRole === "sub_group_admin" ? (
                              <AdminTag>Admin</AdminTag>
                            ) : subscriptionGroupRole === "sub_group_owner" ? (
                              <AdminTag>Owner</AdminTag>
                            ) : (
                              ""
                            )}
                          </span>
                        </td>
                        <td>
                          <span style={{ textTransform: "lowercase" }}>
                            {member?.email || "--"}
                          </span>
                        </td>
                        <td>
                          <StatusMember
                            pending={member.status === "invite_pending"}
                          >
                            {member.status === "invite_pending"
                              ? "pending"
                              : status}
                          </StatusMember>
                        </td>
                        <td>
                          <span>
                            {member.user?.createdAt
                              ? dayjs(member.user?.createdAt).format("M/D/YYYY")
                              : "--"}
                          </span>
                        </td>
                        <td
                          style={{
                            width: "auto",
                            padding: "0",
                            textAlign: "right",
                            paddingRight: "28px",
                          }}
                        >
                          {subscriptionGroupRole !== "sub_group_owner" && (
                            <MoreMenuContainer>
                              <MoreMenu
                                type={AccountType.Organization}
                                id={member?.id}
                                status={
                                  status === "active" &&
                                  subscriptionGroupRole === "sub_group_member"
                                    ? "Assign Admin"
                                    : status === "active" &&
                                      subscriptionGroupRole ===
                                        "sub_group_admin"
                                    ? "Unassign admin"
                                    : ""
                                }
                                title={member?.user?.firstName}
                                menuPosition="auto"
                                iconSize={"S"}
                                cb={(id, type) => {
                                  if (type === "deleteMember") {
                                    setDeleteId(id);
                                    setMemberModalContent("delete-modal");
                                  } else if (type === "resendInvite") {
                                    resendInvite(id);
                                  } else {
                                    assignAdmin(
                                      member?.user?.id,
                                      subscriptionGroupRole ===
                                        "sub_group_admin"
                                    );
                                  }
                                }}
                                triangle
                                resendInvite={status !== "active"}
                              />
                            </MoreMenuContainer>
                          )}
                        </td>
                      </tr>
                    );
                  })
                ) : isSearchActive ? (
                  <tr>
                    <td
                      style={{ textAlign: "center", height: "68px" }}
                      colSpan={7}
                    >
                      <span style={{ color: "black" }}>
                        No members match your search query.
                      </span>
                    </td>
                  </tr>
                ) : null}
              </Tbody>
            </Table>
          </TableContainer>
        )}
        {!!memberModalContent?.length && (
          <MemberModalContent
            onClose={() => {
              setMemberModalContent("");
              setInvitationModal(false);
              onClose?.();
            }}
            type={memberModalContent}
            setRefetch={setRefetch}
            setMemberModalContent={setMemberModalContent}
            memberLimit={memberLimit}
            onDelete={() => deleteMember(deleteId)}
            isLoading={isLoadingDelete}
          />
        )}
        {selectedMembers?.length > 1 &&
          isVisibleBulkActionBar === ModalBulkActionType.BulkActionBar && (
            <BulkActionBar>
              <MembersSelected>
                {selectedMembers?.length}{" "}
                {selectedMembers?.length > 1 ? "Members" : "Member"} Selected:
              </MembersSelected>
              <ResendInvite
                onClick={() =>
                  setIsVisibleBulkActionBar(ModalBulkActionType.Resend)
                }
              >
                Resend Invite
              </ResendInvite>
              <Deactivate
                onClick={() =>
                  setIsVisibleBulkActionBar(ModalBulkActionType.Delete)
                }
              >
                Delete
              </Deactivate>
              <Close onClick={() => setIsVisibleBulkActionBar(null)}>
                <Icon name={X} />
              </Close>
            </BulkActionBar>
          )}
      </Container>
      {(isVisibleBulkActionBar === ModalBulkActionType.Resend ||
        isVisibleBulkActionBar === ModalBulkActionType.Delete) && (
        <BulkActionBarModal
          onClose={() => {
            setIsVisibleBulkActionBar(null);
            setSelectedMembers([]);
            setSelectAll(false);
          }}
          type={isVisibleBulkActionBar}
          setRefetch={setRefetch}
          listMember={selectedMembers}
        />
      )}
    </Fragment>
  );
};

export default MemberListTable;
