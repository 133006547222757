import { FunctionalComponent, h, Fragment } from "preact";
import { useState, useRef, useEffect, useLayoutEffect } from "preact/hooks";
import { styled } from "linaria/react";

import { copyToClipboard } from "util/videoUtil";
import { Favorite as FavoriteType } from "models/favorite";
import { useAnalytics } from "hooks/analyticsHook";
import Colors from "style/colors";
import Icon, { IconTypes } from "components/shared/Icon";
import Text from "components/shared/Text";
import ConfirmModal from "components/shared/ConfirmModal";
import { DisplayOverlay } from "style/layout";

import useUser from "hooks/userHook";
import useFavorites from "hooks/favoritesHook";
import { useUserPaths } from "hooks/pathHook";
import { useBranch } from "hooks/branchHook";
import { appRoute } from "util/routerUtil";
import { useContext } from "preact/hooks";
import ToastContext from "util/context/ToastContext";
import mq from "style/breakpoints";
import { AccountType } from "models/subscription";
import { TargetArea } from "models/videoDetail";
import { RenderIcon } from "components/layout/RenderIconBuildProgram";

const Dropdown = styled.div<{ position: string | undefined }>`
  position: relative;
  color: ${Colors.txtLight};
  margin-left: 1rem;

  > div:nth-child(3) {
    top: inherit;
    max-width: inherit;
    > div {
      border-radius: 0.75rem;
    }
    ${mq.minXs} {
      top: 0;
      max-width: 22.5rem;
    }
  }
  ${mq.md} {
    top: 0;
    margin-left: 0;
  }
  ${mq.xs} {
    margin: 0;
  }
`;

const IconContainer = styled.div`
  cursor: pointer;
  transition: opacity 0.25s ease-in-out;
  :hover {
    opacity: 0.9;
  }
  span:before {
    font-size: 15px;
  }
  ${mq.xs} {
    span {
      display: flex;
    }
  }
`;

const Menu = styled.div<{ isVisible: boolean; type: string }>`
  position: absolute;
  z-index: 4;
  flex-flow: column;
  padding: 0;
  display: ${({ isVisible }) => (isVisible ? "flex" : "none")};
  opacity: ${({ isVisible }) => (isVisible ? "1" : "0")};
  transition: opacity 100ms ease-in-out;
  background-color: ${({ type }) =>
    type === "videoPlayer" ? Colors.bgLight2 : Colors.bgDark1};
  border: 1px solid ${Colors.bgDark3};
  border-radius: 0.25rem;
  width: auto;
`;

const Item = styled.div<{ type?: string }>`
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: 0.25rem;
  padding: 0.75rem 1.25rem;
  position: relative;
  span {
    white-space: nowrap;
    color: ${({ type }) =>
      type === "videoPlayer" ? Colors.txtDark : Colors.bgLight2};
  }
  span:first-child {
    margin-right: ${({ type }) => (type === "videoPlayer" ? "0" : "0.5rem")};
  }
  :hover {
    transition: opacity 0.25s ease-in-out;
    opacity: 0.9;
  }

  button {
    border: none;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    cursor: pointer;
    svg {
      display: none;
    }
  }
`;

const ConfirmModalContainer = styled.div`
  > div:nth-child(1) {
    max-width: 439px;
    height: 310px;
    padding: 32px;

    > div:nth-child(1) {
      h1 {
        margin-bottom: 8px;
      }
      span {
        color: #8e8e93;
        margin-bottom: 32px;
      }
    }
    > div:nth-child(2) {
      display: block !important;

      span {
        display: block;
      }
      > div {
        padding: 16px !important;
        border-radius: 8px;
        margin-right: 0 !important;
      }
      > div:nth-child(1) {
        margin-bottom: 10px !important;
      }
      > div:nth-child(2) {
        span {
          font-weight: 600;
          font-size: 18px;
          line-height: 24px;
          color: #000000;
        }
      }
    }
  }
  > div:nth-child(2) {
    background: #000000 !important ;
    opacity: 0.4 !important;
    border-radius: 0 !important ;
  }
`;

const Triangle = styled.div`
  position: absolute;
  width: 18px !important;
  height: 18px !important;
  background: #151515;
  border-top-right-radius: 4px !important;
  transform: rotate(-45deg);
  top: -9px;
  right: 10px;
  z-index: 2001;
`;

interface MoreMenuProps {
  type:
    | "path"
    | "routine"
    | "organization"
    | "videoPlayer"
    | "program"
    | "collection"
    | "single-path";
  id?: any;
  slug?: string;
  pathId?: number;
  title: string;
  description?: string;
  thumbnailUrl?: string;
  menuPosition?: "auto" | "center";
  iconSize?: "L" | "M" | "S";
  cb?: (data: number, type?: string) => Promise<void> | void;
  status?: string;
  moreMenuIsVisible?: (isVisible: boolean) => void;
  durationInSeconds?: number;
  targetAreas?: TargetArea[];
  pathOverviewIsVisible?: (isVisible: boolean) => void;
  setResetPath?: (data: any) => void;
  onClose?: () => void;
  onOpen?: () => void;
  textSkipWeek?: string;
  setProgramOverview?: () => void;
  setShareProgress?: () => void;
  setSkipWeek?: () => void;
  setEndProgram?: () => void;
  showFavorite?: boolean;
  triangle?: boolean;
  resendInvite?: boolean;
}

const MoreMenu: FunctionalComponent<MoreMenuProps> = ({
  type = "routine",
  id,
  slug,
  pathId,
  title,
  description,
  thumbnailUrl,
  menuPosition,
  iconSize,
  cb,
  status,
  moreMenuIsVisible,
  durationInSeconds = 0,
  targetAreas,
  pathOverviewIsVisible,
  setResetPath,
  onClose,
  onOpen,
  textSkipWeek,
  setProgramOverview,
  setShareProgress,
  setSkipWeek,
  setEndProgram,
  showFavorite = true,
  triangle = false,
  resendInvite = false,
}: MoreMenuProps) => {
  const { More, Favorite, FavoriteFilled, Share, ResetProgress, LeavePath } =
    IconTypes;
  const { user } = useUser();
  const { track } = useAnalytics();
  const { shareDeepLink } = useBranch();
  const [isFetching, setIsFetching] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [isOutsideToTheRight, setIsOutsideToTheRight] = useState(false);
  const [isOutsideToTheBottom, setIsOutsideToTheBottom] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [tags, setTags] = useState<string[]>([]);
  const { favorites, addFavorite, removeFavorite } = useFavorites();
  const { resetPath } = useUserPaths();
  const { triggerToast } = useContext(ToastContext);
  const [nameModal, setNameModal] = useState("");
  const videoPlayer = type === "videoPlayer";
  const program = type === "program";
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = (e) => {
    e.stopPropagation();
    if (nameModal === "") {
      setIsVisible(!isVisible);
      moreMenuIsVisible && moreMenuIsVisible(!isVisible);
      onOpen?.();
    }
  };

  const toggleFavorite = async (favorite: FavoriteType | null) => {
    if (!user) appRoute("/signup");

    if (isFetching) return;

    setIsFetching(true);
    triggerToast(
      !favorite ? "Added to favorites" : "Removed from favorites",
      videoPlayer ? "player" : ""
    );

    if (favorite) {
      track("Content Favorited Removed", {
        name: title,
        contentId: id,
        category: "Routine",
        duration: Number((durationInSeconds / 60).toFixed(2)),
        tags,
        description: description,
        source: "Routine",
      });
      removeFavorite(favorite);
    } else {
      track("Content Favorited", {
        name: title,
        contentId: id,
        category: "Routine",
        duration: (durationInSeconds / 60).toFixed(2),
        tags,
        description: description,
        source: "Routine",
      });
      await addFavorite(id);
    }

    setIsFetching(false);
  };

  const getDeepLink = () => {
    const deepLink = shareDeepLink(
      { id, slug, title, description, thumbnailUrl },
      type === "collection" ? "explore" : type === "path" ? "path" : "routine"
    );

    return deepLink;
  };

  const deepLink = useRef("");

  useEffect(() => {
    (async () => {
      deepLink.current = await getDeepLink();
    })();
  }, [slug]);

  const handleCopyToClipboard = async (e) => {
    e.stopPropagation();
    track("Content Shared", {
      name: title,
      contentId: id,
      category: type,
      duration: Number((durationInSeconds / 60).toFixed(2)),
      tags: tags,
      description: description,
      isFavorited: favorite ? true : false,
      source: type,
    });
    try {
      triggerToast("Copied to clipboard", videoPlayer ? "player" : "");
      setIsVisible(false);
      copyToClipboard(deepLink.current);
      moreMenuIsVisible && moreMenuIsVisible(false);
    } catch (error) {
      console.error("ERROR", error);
    }
  };

  const handleLeavePath = async (e) => {
    e.stopPropagation();
    setNameModal("");
    // TODO: Generalize this component to accept a general list of items,
    // instead of the custom scenarios handled right now
    if (!user) appRoute("/signup");
    setIsModalVisible(false);
    setIsVisible(false);
    cb && (await cb(id));
    track("Path Left", {
      pathId: pathId,
      path: title,
      platform: "Web",
    });
    triggerToast("Successfully left path");
  };

  const handleRestartPath = async (e) => {
    e.stopPropagation();
    if (!user) appRoute("/signup");

    if (resetPath) {
      setIsLoading(true);
      const resp = await resetPath(id);
      setResetPath && setResetPath(resp);
      setNameModal("");
      setIsLoading(false);
    }
    triggerToast("Progress reset");
    setIsModalVisible(false);
    track("Path Restarted", {
      pathId: pathId,
      path: title,
      platform: "Web",
    });
  };

  const handleDeleteMember = (e) => {
    e.stopPropagation();
    setIsVisible(false);
    cb && cb(id, "deleteMember");
  };

  const handleResendInvite = (e) => {
    e.stopPropagation();

    if (!status) {
      setIsVisible(false);
      cb && cb(id, "resendInvite");
    }
  };

  const handleAssignAdmin = (e) => {
    e.stopPropagation();

    if (status) {
      setIsVisible(false);
      cb && cb(id, "assignAdmin");
    }
  };

  const innerHeight =
    typeof window !== "undefined" &&
    (window.innerWidth < 769 ? window.innerHeight - 60 : window.innerHeight);
  const clientHeight =
    typeof window !== "undefined" &&
    (window.innerWidth < 769
      ? document.documentElement.clientHeight - 60
      : document.documentElement.clientHeight);

  const favorite =
    favorites?.find((favorite) => favorite.video.id === id) ?? null;

  const menu = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    const rect = menu?.current?.getBoundingClientRect();
    const mainWidth =
      document.getElementsByTagName("main")[0]?.getBoundingClientRect().right ||
      null;

    if (rect && isVisible) {
      if (
        typeof window !== "undefined" &&
        (rect.right >
          (window.innerWidth || document.documentElement.clientWidth) ||
          (mainWidth && rect.right > mainWidth))
      )
        setIsOutsideToTheRight(true);
      if (rect.bottom > Number(innerHeight || clientHeight))
        setIsOutsideToTheBottom(true);
    }

    if (!isVisible) {
      setIsOutsideToTheRight(false);
      setIsOutsideToTheBottom(false);
    }
  }, [isVisible]);

  useEffect(() => {
    if (!tags.length) {
      targetAreas?.map((item) =>
        setTags((prevState) => [...prevState, item.content])
      );
    }
  }, [targetAreas]);

  return (
    <Dropdown
      position={menuPosition}
      onClick={handleClick}
      onTouchEnd={(e) => e.stopPropagation()}
      onTouchMove={(e) => e.stopPropagation()}
      onTouchStart={(e) => e.stopPropagation()}
      className="more-menu"
    >
      <IconContainer className="icon-container">
        <Icon name={More} size={iconSize || "L"} color={"black"} />
      </IconContainer>
      <Fragment>
        <Menu
          isVisible={isVisible}
          ref={menu}
          style={{
            left: isOutsideToTheRight ? "unset" : "-1.5rem",
            right: isOutsideToTheRight ? "0" : "unset",
            top: isOutsideToTheBottom ? "unset" : "1.5rem",
            bottom: isOutsideToTheBottom ? "3rem" : "unset",
          }}
          type={type}
          className="menu"
        >
          {triangle && <Triangle />}
          {type === "routine" || type === "collection" || type === "path" ? (
            <Fragment>
              {showFavorite && (
                <Item
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleFavorite(favorite);
                  }}
                  onTouchEnd={(e) => e.stopPropagation()}
                  onTouchMove={(e) => e.stopPropagation()}
                  onTouchStart={(e) => e.stopPropagation()}
                >
                  <Icon
                    name={favorite || isFetching ? FavoriteFilled : Favorite}
                    size="S"
                  />
                  <Text size="S">
                    {favorite || isFetching
                      ? "Remove from Favorites"
                      : "Favorite"}
                  </Text>
                </Item>
              )}
              <Item
                onClick={handleCopyToClipboard}
                onTouchEnd={(e) => e.stopPropagation()}
                onTouchMove={(e) => e.stopPropagation()}
                onTouchStart={(e) => e.stopPropagation()}
              >
                <Icon name={Share} size="S" />
                <Text size="S">
                  <Fragment>Copy link to {type}</Fragment>
                </Text>{" "}
              </Item>
            </Fragment>
          ) : type === AccountType.Organization ? (
            <Fragment>
              <Item
                onClick={handleDeleteMember}
                onTouchEnd={(e) => e.stopPropagation()}
                onTouchMove={(e) => e.stopPropagation()}
                onTouchStart={(e) => e.stopPropagation()}
              >
                <Text size="S">Delete</Text>
              </Item>
              {resendInvite && (
                <Item
                  onClick={handleResendInvite}
                  onTouchEnd={(e) => e.stopPropagation()}
                  onTouchMove={(e) => e.stopPropagation()}
                  onTouchStart={(e) => e.stopPropagation()}
                >
                  <Text size="S">Resend Invite</Text>
                </Item>
              )}
              {status && (
                <Item
                  onClick={handleAssignAdmin}
                  onTouchEnd={(e) => e.stopPropagation()}
                  onTouchMove={(e) => e.stopPropagation()}
                  onTouchStart={(e) => e.stopPropagation()}
                >
                  <Text size="S">{status}</Text>
                </Item>
              )}
            </Fragment>
          ) : program ? (
            <Fragment>
              <Item
                onClick={setProgramOverview}
                onTouchEnd={(e) => e.stopPropagation()}
                onTouchMove={(e) => e.stopPropagation()}
                onTouchStart={(e) => e.stopPropagation()}
              >
                {RenderIcon("info-icon")}
                <Text size="S">Program Overview</Text>{" "}
              </Item>
              <Item
                onClick={setShareProgress}
                onTouchEnd={(e) => e.stopPropagation()}
                onTouchMove={(e) => e.stopPropagation()}
                onTouchStart={(e) => e.stopPropagation()}
              >
                {RenderIcon("share-icon")}
                <Text size="S">Share Progress</Text>{" "}
              </Item>
              <Item
                onClick={setSkipWeek}
                onTouchEnd={(e) => e.stopPropagation()}
                onTouchMove={(e) => e.stopPropagation()}
                onTouchStart={(e) => e.stopPropagation()}
              >
                {RenderIcon("skip-icon")}
                <Text size="S">
                  <Fragment>{textSkipWeek}</Fragment>
                </Text>{" "}
              </Item>
              <Item
                onClick={setEndProgram}
                onTouchEnd={(e) => e.stopPropagation()}
                onTouchMove={(e) => e.stopPropagation()}
                onTouchStart={(e) => e.stopPropagation()}
              >
                {RenderIcon("end-icon")}
                <Text size="S">End Program</Text>
              </Item>
            </Fragment>
          ) : videoPlayer ? (
            <Fragment>
              <Item
                onClick={(e) => {
                  e.stopPropagation();
                  toggleFavorite(favorite);
                  setIsVisible(false);
                }}
                onTouchEnd={(e) => e.stopPropagation()}
                onTouchMove={(e) => e.stopPropagation()}
                onTouchStart={(e) => e.stopPropagation()}
                type={type}
              >
                <Icon
                  name={
                    favorite ? IconTypes.FavoriteFilled : IconTypes.Favorite
                  }
                />
                <Text size="S">
                  {favorite ? "Remove from Favorites" : "Favorite"}
                </Text>
              </Item>
              {typeof window !== "undefined" &&
                /Chrome/.test(navigator.userAgent) && (
                  <Item
                    onClick={(e) => {
                      e.stopPropagation();
                      setIsVisible(false);
                    }}
                    onTouchEnd={(e) => e.stopPropagation()}
                    onTouchMove={(e) => e.stopPropagation()}
                    onTouchStart={(e) => e.stopPropagation()}
                    type={type}
                  >
                    <Icon name={IconTypes.Cast} />
                    <Text size="S">Cast</Text>
                    <button is="google-cast-button" />
                  </Item>
                )}
              <Item
                onClick={handleCopyToClipboard}
                onTouchEnd={(e) => e.stopPropagation()}
                onTouchMove={(e) => e.stopPropagation()}
                onTouchStart={(e) => e.stopPropagation()}
                type={type}
              >
                <Icon name={IconTypes.Share} />
                <Text size="S">Share</Text>
              </Item>
            </Fragment>
          ) : (
            <Fragment>
              <Item
                onClick={(e) => {
                  e.stopPropagation();
                  setIsVisible(false);
                  pathOverviewIsVisible && pathOverviewIsVisible(true);
                }}
                onTouchEnd={(e) => e.stopPropagation()}
                onTouchMove={(e) => e.stopPropagation()}
                onTouchStart={(e) => e.stopPropagation()}
              >
                <Icon name={IconTypes.Warning} size="S" />
                <Text size="S">Path Overview</Text>
              </Item>
              {id && (
                <Fragment>
                  <Item
                    onClick={(e) => {
                      e.stopPropagation();
                      setIsVisible(false);
                      setIsModalVisible(true);
                      setNameModal("Reset Progress");
                      track("CTA Tapped", {
                        name: "Reset Path",
                        source: "Path",
                      });
                    }}
                    onTouchEnd={(e) => e.stopPropagation()}
                    onTouchMove={(e) => e.stopPropagation()}
                    onTouchStart={(e) => e.stopPropagation()}
                  >
                    <Icon name={ResetProgress} size="S" />
                    <Text size="S">Reset Progress</Text>
                  </Item>
                  <Item
                    onClick={(e) => {
                      e.stopPropagation();
                      setIsVisible(false);
                      setIsModalVisible(true);
                      setNameModal("Leave Path");
                    }}
                    onTouchEnd={(e) => e.stopPropagation()}
                    onTouchMove={(e) => e.stopPropagation()}
                    onTouchStart={(e) => e.stopPropagation()}
                  >
                    <Icon name={LeavePath} size="S" />
                    <Text size="S">Leave Path</Text>
                  </Item>
                </Fragment>
              )}
              <Item
                onClick={handleCopyToClipboard}
                onTouchEnd={(e) => e.stopPropagation()}
                onTouchMove={(e) => e.stopPropagation()}
                onTouchStart={(e) => e.stopPropagation()}
                type={type}
              >
                <Icon name={IconTypes.Share} />
                <Text size="S">Share</Text>
              </Item>
            </Fragment>
          )}
        </Menu>
        {isVisible && (
          <DisplayOverlay
            style={{ maxWidth: "100%", top: "0" }}
            onClick={(e) => {
              e.stopPropagation();
              setIsVisible(false);
              onClose?.();
            }}
            onTouchEnd={(e) => e.stopPropagation()}
            onTouchMove={(e) => e.stopPropagation()}
            onTouchStart={(e) => e.stopPropagation()}
            onMouseUp={() => {
              setIsVisible(false);
              moreMenuIsVisible && moreMenuIsVisible(false);
              onClose?.();
            }}
            className="display-overlay"
          />
        )}
      </Fragment>
      {isModalVisible && (
        <ConfirmModalContainer
          className={`${isModalVisible ? "confirm-modal" : ""}`}
        >
          <ConfirmModal
            template={"horizontal"}
            title={"Are you sure?"}
            question={`You will lose your current progress if you ${
              nameModal === "Reset Progress" ? "reset" : "leave"
            } this Path.`}
            confirm={{
              text:
                nameModal === "Reset Progress" ? "Restart Path" : "Leave Path",
              onConfirm:
                nameModal === "Reset Progress"
                  ? handleRestartPath
                  : handleLeavePath,
            }}
            cancel={{
              text: "Cancel",
              onCancel: (e) => {
                e.stopPropagation();
                setIsModalVisible(false);
                setIsVisible(false);
                setNameModal("");
              },
            }}
            loading={isLoading}
            unwrapped={!isLoading}
          />
        </ConfirmModalContainer>
      )}
    </Dropdown>
  );
};

export default MoreMenu;
