import { h } from "preact";

export const RenderIconMenuMobile = (icon: string) => {
  switch (icon) {
    case "menu":
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.75 12H20.25"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M3.75 6H20.25"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M3.75 18H20.25"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case "edit-profile":
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M19.3273 12.4083V19.8544C19.3273 20.4871 18.8101 21 18.1721 21H3.15515C2.51718 21 2 20.4871 2 19.8544V4.96217C2 4.3295 2.51718 3.81661 3.15515 3.81661H10.6636M19.6193 6.79072L17.1688 4.36062M11.6012 13.7615L14.0516 12.9515C14.2218 12.8952 14.3763 12.8005 14.5031 12.6747L21.6617 5.57566C22.1128 5.12829 22.1128 4.40296 21.6617 3.95559L20.028 2.33553C19.5769 1.88816 18.8455 1.88816 18.3944 2.33553L11.2359 9.4346C11.1091 9.56035 11.0135 9.71366 10.9568 9.88237L10.14 12.3125C9.83899 13.208 10.6981 14.06 11.6012 13.7615Z"
            stroke="#FF0000"
            strokeWidth="1.5"
            strokeLinecap="round"
            style="mix-blend-mode:saturation"
          />
          <path
            d="M19.3273 12.4083V19.8544C19.3273 20.4871 18.8101 21 18.1721 21H3.15515C2.51718 21 2 20.4871 2 19.8544V4.96217C2 4.3295 2.51718 3.81661 3.15515 3.81661H10.6636M19.6193 6.79072L17.1688 4.36062M11.6012 13.7615L14.0516 12.9515C14.2218 12.8952 14.3763 12.8005 14.5031 12.6747L21.6617 5.57566C22.1128 5.12829 22.1128 4.40296 21.6617 3.95559L20.028 2.33553C19.5769 1.88816 18.8455 1.88816 18.3944 2.33553L11.2359 9.4346C11.1091 9.56035 11.0135 9.71366 10.9568 9.88237L10.14 12.3125C9.83899 13.208 10.6981 14.06 11.6012 13.7615Z"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            style="mix-blend-mode:difference"
          />
          <path
            d="M19.3273 12.4083V19.8544C19.3273 20.4871 18.8101 21 18.1721 21H3.15515C2.51718 21 2 20.4871 2 19.8544V4.96217C2 4.3295 2.51718 3.81661 3.15515 3.81661H10.6636M19.6193 6.79072L17.1688 4.36062M11.6012 13.7615L14.0516 12.9515C14.2218 12.8952 14.3763 12.8005 14.5031 12.6747L21.6617 5.57566C22.1128 5.12829 22.1128 4.40296 21.6617 3.95559L20.028 2.33553C19.5769 1.88816 18.8455 1.88816 18.3944 2.33553L11.2359 9.4346C11.1091 9.56035 11.0135 9.71366 10.9568 9.88237L10.14 12.3125C9.83899 13.208 10.6981 14.06 11.6012 13.7615Z"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            style="mix-blend-mode:saturation"
          />
          <path
            d="M19.3273 12.4083V19.8544C19.3273 20.4871 18.8101 21 18.1721 21H3.15515C2.51718 21 2 20.4871 2 19.8544V4.96217C2 4.3295 2.51718 3.81661 3.15515 3.81661H10.6636M19.6193 6.79072L17.1688 4.36062M11.6012 13.7615L14.0516 12.9515C14.2218 12.8952 14.3763 12.8005 14.5031 12.6747L21.6617 5.57566C22.1128 5.12829 22.1128 4.40296 21.6617 3.95559L20.028 2.33553C19.5769 1.88816 18.8455 1.88816 18.3944 2.33553L11.2359 9.4346C11.1091 9.56035 11.0135 9.71366 10.9568 9.88237L10.14 12.3125C9.83899 13.208 10.6981 14.06 11.6012 13.7615Z"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            style="mix-blend-mode:overlay"
          />
          <path
            d="M19.3273 12.4083V19.8544C19.3273 20.4871 18.8101 21 18.1721 21H3.15515C2.51718 21 2 20.4871 2 19.8544V4.96217C2 4.3295 2.51718 3.81661 3.15515 3.81661H10.6636M19.6193 6.79072L17.1688 4.36062M11.6012 13.7615L14.0516 12.9515C14.2218 12.8952 14.3763 12.8005 14.5031 12.6747L21.6617 5.57566C22.1128 5.12829 22.1128 4.40296 21.6617 3.95559L20.028 2.33553C19.5769 1.88816 18.8455 1.88816 18.3944 2.33553L11.2359 9.4346C11.1091 9.56035 11.0135 9.71366 10.9568 9.88237L10.14 12.3125C9.83899 13.208 10.6981 14.06 11.6012 13.7615Z"
            stroke="black"
            strokeWidth="1.5"
            strokeLinecap="round"
            style="mix-blend-mode:overlay"
          />
          <path
            d="M19.3273 12.4083V19.8544C19.3273 20.4871 18.8101 21 18.1721 21H3.15515C2.51718 21 2 20.4871 2 19.8544V4.96217C2 4.3295 2.51718 3.81661 3.15515 3.81661H10.6636M19.6193 6.79072L17.1688 4.36062M11.6012 13.7615L14.0516 12.9515C14.2218 12.8952 14.3763 12.8005 14.5031 12.6747L21.6617 5.57566C22.1128 5.12829 22.1128 4.40296 21.6617 3.95559L20.028 2.33553C19.5769 1.88816 18.8455 1.88816 18.3944 2.33553L11.2359 9.4346C11.1091 9.56035 11.0135 9.71366 10.9568 9.88237L10.14 12.3125C9.83899 13.208 10.6981 14.06 11.6012 13.7615Z"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            style="mix-blend-mode:overlay"
          />
          <path
            d="M19.3273 12.4083V19.8544C19.3273 20.4871 18.8101 21 18.1721 21H3.15515C2.51718 21 2 20.4871 2 19.8544V4.96217C2 4.3295 2.51718 3.81661 3.15515 3.81661H10.6636M19.6193 6.79072L17.1688 4.36062M11.6012 13.7615L14.0516 12.9515C14.2218 12.8952 14.3763 12.8005 14.5031 12.6747L21.6617 5.57566C22.1128 5.12829 22.1128 4.40296 21.6617 3.95559L20.028 2.33553C19.5769 1.88816 18.8455 1.88816 18.3944 2.33553L11.2359 9.4346C11.1091 9.56035 11.0135 9.71366 10.9568 9.88237L10.14 12.3125C9.83899 13.208 10.6981 14.06 11.6012 13.7615Z"
            stroke="black"
            strokeWidth="1.5"
            strokeLinecap="round"
            style="mix-blend-mode:overlay"
          />
        </svg>
      );
    case "security":
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.17157 4.17157C9.92172 3.42143 10.9391 3 12 3C13.0609 3 14.0783 3.42143 14.8284 4.17157C15.5786 4.92172 16 5.93913 16 7V10H8V7C8 5.93913 8.42143 4.92172 9.17157 4.17157ZM6 10V7C6 5.4087 6.63214 3.88258 7.75736 2.75736C8.88258 1.63214 10.4087 1 12 1C13.5913 1 15.1174 1.63214 16.2426 2.75736C17.3679 3.88258 18 5.4087 18 7V10H19C20.6569 10 22 11.3431 22 13V20C22 21.6569 20.6569 23 19 23H5C3.34315 23 2 21.6569 2 20V13C2 11.3431 3.34315 10 5 10H6ZM17 12H7H5C4.44772 12 4 12.4477 4 13V20C4 20.5523 4.44772 21 5 21H19C19.5523 21 20 20.5523 20 20V13C20 12.4477 19.5523 12 19 12H17Z"
            fill="#FF0000"
            style="mix-blend-mode:saturation"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.17157 4.17157C9.92172 3.42143 10.9391 3 12 3C13.0609 3 14.0783 3.42143 14.8284 4.17157C15.5786 4.92172 16 5.93913 16 7V10H8V7C8 5.93913 8.42143 4.92172 9.17157 4.17157ZM6 10V7C6 5.4087 6.63214 3.88258 7.75736 2.75736C8.88258 1.63214 10.4087 1 12 1C13.5913 1 15.1174 1.63214 16.2426 2.75736C17.3679 3.88258 18 5.4087 18 7V10H19C20.6569 10 22 11.3431 22 13V20C22 21.6569 20.6569 23 19 23H5C3.34315 23 2 21.6569 2 20V13C2 11.3431 3.34315 10 5 10H6ZM17 12H7H5C4.44772 12 4 12.4477 4 13V20C4 20.5523 4.44772 21 5 21H19C19.5523 21 20 20.5523 20 20V13C20 12.4477 19.5523 12 19 12H17Z"
            fill="white"
            style="mix-blend-mode:difference"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.17157 4.17157C9.92172 3.42143 10.9391 3 12 3C13.0609 3 14.0783 3.42143 14.8284 4.17157C15.5786 4.92172 16 5.93913 16 7V10H8V7C8 5.93913 8.42143 4.92172 9.17157 4.17157ZM6 10V7C6 5.4087 6.63214 3.88258 7.75736 2.75736C8.88258 1.63214 10.4087 1 12 1C13.5913 1 15.1174 1.63214 16.2426 2.75736C17.3679 3.88258 18 5.4087 18 7V10H19C20.6569 10 22 11.3431 22 13V20C22 21.6569 20.6569 23 19 23H5C3.34315 23 2 21.6569 2 20V13C2 11.3431 3.34315 10 5 10H6ZM17 12H7H5C4.44772 12 4 12.4477 4 13V20C4 20.5523 4.44772 21 5 21H19C19.5523 21 20 20.5523 20 20V13C20 12.4477 19.5523 12 19 12H17Z"
            fill="white"
            style="mix-blend-mode:saturation"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.17157 4.17157C9.92172 3.42143 10.9391 3 12 3C13.0609 3 14.0783 3.42143 14.8284 4.17157C15.5786 4.92172 16 5.93913 16 7V10H8V7C8 5.93913 8.42143 4.92172 9.17157 4.17157ZM6 10V7C6 5.4087 6.63214 3.88258 7.75736 2.75736C8.88258 1.63214 10.4087 1 12 1C13.5913 1 15.1174 1.63214 16.2426 2.75736C17.3679 3.88258 18 5.4087 18 7V10H19C20.6569 10 22 11.3431 22 13V20C22 21.6569 20.6569 23 19 23H5C3.34315 23 2 21.6569 2 20V13C2 11.3431 3.34315 10 5 10H6ZM17 12H7H5C4.44772 12 4 12.4477 4 13V20C4 20.5523 4.44772 21 5 21H19C19.5523 21 20 20.5523 20 20V13C20 12.4477 19.5523 12 19 12H17Z"
            fill="white"
            style="mix-blend-mode:overlay"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.17157 4.17157C9.92172 3.42143 10.9391 3 12 3C13.0609 3 14.0783 3.42143 14.8284 4.17157C15.5786 4.92172 16 5.93913 16 7V10H8V7C8 5.93913 8.42143 4.92172 9.17157 4.17157ZM6 10V7C6 5.4087 6.63214 3.88258 7.75736 2.75736C8.88258 1.63214 10.4087 1 12 1C13.5913 1 15.1174 1.63214 16.2426 2.75736C17.3679 3.88258 18 5.4087 18 7V10H19C20.6569 10 22 11.3431 22 13V20C22 21.6569 20.6569 23 19 23H5C3.34315 23 2 21.6569 2 20V13C2 11.3431 3.34315 10 5 10H6ZM17 12H7H5C4.44772 12 4 12.4477 4 13V20C4 20.5523 4.44772 21 5 21H19C19.5523 21 20 20.5523 20 20V13C20 12.4477 19.5523 12 19 12H17Z"
            fill="black"
            style="mix-blend-mode:overlay"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.17157 4.17157C9.92172 3.42143 10.9391 3 12 3C13.0609 3 14.0783 3.42143 14.8284 4.17157C15.5786 4.92172 16 5.93913 16 7V10H8V7C8 5.93913 8.42143 4.92172 9.17157 4.17157ZM6 10V7C6 5.4087 6.63214 3.88258 7.75736 2.75736C8.88258 1.63214 10.4087 1 12 1C13.5913 1 15.1174 1.63214 16.2426 2.75736C17.3679 3.88258 18 5.4087 18 7V10H19C20.6569 10 22 11.3431 22 13V20C22 21.6569 20.6569 23 19 23H5C3.34315 23 2 21.6569 2 20V13C2 11.3431 3.34315 10 5 10H6ZM17 12H7H5C4.44772 12 4 12.4477 4 13V20C4 20.5523 4.44772 21 5 21H19C19.5523 21 20 20.5523 20 20V13C20 12.4477 19.5523 12 19 12H17Z"
            fill="white"
            style="mix-blend-mode:overlay"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.17157 4.17157C9.92172 3.42143 10.9391 3 12 3C13.0609 3 14.0783 3.42143 14.8284 4.17157C15.5786 4.92172 16 5.93913 16 7V10H8V7C8 5.93913 8.42143 4.92172 9.17157 4.17157ZM6 10V7C6 5.4087 6.63214 3.88258 7.75736 2.75736C8.88258 1.63214 10.4087 1 12 1C13.5913 1 15.1174 1.63214 16.2426 2.75736C17.3679 3.88258 18 5.4087 18 7V10H19C20.6569 10 22 11.3431 22 13V20C22 21.6569 20.6569 23 19 23H5C3.34315 23 2 21.6569 2 20V13C2 11.3431 3.34315 10 5 10H6ZM17 12H7H5C4.44772 12 4 12.4477 4 13V20C4 20.5523 4.44772 21 5 21H19C19.5523 21 20 20.5523 20 20V13C20 12.4477 19.5523 12 19 12H17Z"
            fill="black"
            style="mix-blend-mode:overlay"
          />
        </svg>
      );
    case "plan-payment":
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.14998 6.1501V17.8501H20.85V6.1501H3.14998ZM1.34998 6.0001C1.34998 5.08883 2.08871 4.3501 2.99998 4.3501H21C21.9112 4.3501 22.65 5.08883 22.65 6.0001V18.0001C22.65 18.9114 21.9112 19.6501 21 19.6501H2.99998C2.08871 19.6501 1.34998 18.9114 1.34998 18.0001V6.0001Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.8501 15.7501C14.8501 15.253 15.253 14.8501 15.7501 14.8501H18.7501C19.2472 14.8501 19.6501 15.253 19.6501 15.7501C19.6501 16.2472 19.2472 16.6501 18.7501 16.6501H15.7501C15.253 16.6501 14.8501 16.2472 14.8501 15.7501Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.35 15.7501C10.35 15.253 10.7529 14.8501 11.25 14.8501H12.75C13.247 14.8501 13.65 15.253 13.65 15.7501C13.65 16.2472 13.247 16.6501 12.75 16.6501H11.25C10.7529 16.6501 10.35 16.2472 10.35 15.7501Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.34998 9.08433C1.34998 8.58727 1.75292 8.18433 2.24998 8.18433H21.75C22.247 8.18433 22.65 8.58727 22.65 9.08433C22.65 9.58138 22.247 9.98433 21.75 9.98433H2.24998C1.75292 9.98433 1.34998 9.58138 1.34998 9.08433Z"
            fill="white"
          />
        </svg>
      );
    case "download-app":
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M21.7092 2.29502C21.804 2.3904 21.8757 2.50014 21.9241 2.61722C21.9743 2.73854 21.9996 2.86774 22 2.997L22 3.00069V9C22 9.55228 21.5523 10 21 10C20.4477 10 20 9.55228 20 9V5.41421L10.7071 14.7071C10.3166 15.0976 9.68341 15.0976 9.29288 14.7071C8.90236 14.3166 8.90236 13.6834 9.29288 13.2929L18.5858 4H15C14.4477 4 14 3.55228 14 3C14 2.44772 14.4477 2 15 2H20.9999C21.275 2 21.5242 2.11106 21.705 2.29078L21.7092 2.29502Z"
            fill="#FF0000"
            style="mix-blend-mode:saturation"
          />
          <path
            d="M21.7092 2.29502C21.804 2.3904 21.8757 2.50014 21.9241 2.61722C21.9743 2.73854 21.9996 2.86774 22 2.997L22 3.00069V9C22 9.55228 21.5523 10 21 10C20.4477 10 20 9.55228 20 9V5.41421L10.7071 14.7071C10.3166 15.0976 9.68341 15.0976 9.29288 14.7071C8.90236 14.3166 8.90236 13.6834 9.29288 13.2929L18.5858 4H15C14.4477 4 14 3.55228 14 3C14 2.44772 14.4477 2 15 2H20.9999C21.275 2 21.5242 2.11106 21.705 2.29078L21.7092 2.29502Z"
            fill="white"
            style="mix-blend-mode:difference"
          />
          <path
            d="M21.7092 2.29502C21.804 2.3904 21.8757 2.50014 21.9241 2.61722C21.9743 2.73854 21.9996 2.86774 22 2.997L22 3.00069V9C22 9.55228 21.5523 10 21 10C20.4477 10 20 9.55228 20 9V5.41421L10.7071 14.7071C10.3166 15.0976 9.68341 15.0976 9.29288 14.7071C8.90236 14.3166 8.90236 13.6834 9.29288 13.2929L18.5858 4H15C14.4477 4 14 3.55228 14 3C14 2.44772 14.4477 2 15 2H20.9999C21.275 2 21.5242 2.11106 21.705 2.29078L21.7092 2.29502Z"
            fill="white"
            style="mix-blend-mode:saturation"
          />
          <path
            d="M21.7092 2.29502C21.804 2.3904 21.8757 2.50014 21.9241 2.61722C21.9743 2.73854 21.9996 2.86774 22 2.997L22 3.00069V9C22 9.55228 21.5523 10 21 10C20.4477 10 20 9.55228 20 9V5.41421L10.7071 14.7071C10.3166 15.0976 9.68341 15.0976 9.29288 14.7071C8.90236 14.3166 8.90236 13.6834 9.29288 13.2929L18.5858 4H15C14.4477 4 14 3.55228 14 3C14 2.44772 14.4477 2 15 2H20.9999C21.275 2 21.5242 2.11106 21.705 2.29078L21.7092 2.29502Z"
            fill="white"
            style="mix-blend-mode:overlay"
          />
          <path
            d="M21.7092 2.29502C21.804 2.3904 21.8757 2.50014 21.9241 2.61722C21.9743 2.73854 21.9996 2.86774 22 2.997L22 3.00069V9C22 9.55228 21.5523 10 21 10C20.4477 10 20 9.55228 20 9V5.41421L10.7071 14.7071C10.3166 15.0976 9.68341 15.0976 9.29288 14.7071C8.90236 14.3166 8.90236 13.6834 9.29288 13.2929L18.5858 4H15C14.4477 4 14 3.55228 14 3C14 2.44772 14.4477 2 15 2H20.9999C21.275 2 21.5242 2.11106 21.705 2.29078L21.7092 2.29502Z"
            fill="black"
            style="mix-blend-mode:overlay"
          />
          <path
            d="M21.7092 2.29502C21.804 2.3904 21.8757 2.50014 21.9241 2.61722C21.9743 2.73854 21.9996 2.86774 22 2.997L22 3.00069V9C22 9.55228 21.5523 10 21 10C20.4477 10 20 9.55228 20 9V5.41421L10.7071 14.7071C10.3166 15.0976 9.68341 15.0976 9.29288 14.7071C8.90236 14.3166 8.90236 13.6834 9.29288 13.2929L18.5858 4H15C14.4477 4 14 3.55228 14 3C14 2.44772 14.4477 2 15 2H20.9999C21.275 2 21.5242 2.11106 21.705 2.29078L21.7092 2.29502Z"
            fill="white"
            style="mix-blend-mode:overlay"
          />
          <path
            d="M21.7092 2.29502C21.804 2.3904 21.8757 2.50014 21.9241 2.61722C21.9743 2.73854 21.9996 2.86774 22 2.997L22 3.00069V9C22 9.55228 21.5523 10 21 10C20.4477 10 20 9.55228 20 9V5.41421L10.7071 14.7071C10.3166 15.0976 9.68341 15.0976 9.29288 14.7071C8.90236 14.3166 8.90236 13.6834 9.29288 13.2929L18.5858 4H15C14.4477 4 14 3.55228 14 3C14 2.44772 14.4477 2 15 2H20.9999C21.275 2 21.5242 2.11106 21.705 2.29078L21.7092 2.29502Z"
            fill="black"
            style="mix-blend-mode:overlay"
          />
          <path
            d="M4.29289 7.29289C4.48043 7.10536 4.73478 7 5 7H11C11.5523 7 12 6.55228 12 6C12 5.44772 11.5523 5 11 5H5C4.20435 5 3.44129 5.31607 2.87868 5.87868C2.31607 6.44129 2 7.20435 2 8V19C2 19.7957 2.31607 20.5587 2.87868 21.1213C3.44129 21.6839 4.20435 22 5 22H16C16.7957 22 17.5587 21.6839 18.1213 21.1213C18.6839 20.5587 19 19.7957 19 19V13C19 12.4477 18.5523 12 18 12C17.4477 12 17 12.4477 17 13V19C17 19.2652 16.8946 19.5196 16.7071 19.7071C16.5196 19.8946 16.2652 20 16 20H5C4.73478 20 4.48043 19.8946 4.29289 19.7071C4.10536 19.5196 4 19.2652 4 19V8C4 7.73478 4.10536 7.48043 4.29289 7.29289Z"
            fill="#FF0000"
            style="mix-blend-mode:saturation"
          />
          <path
            d="M4.29289 7.29289C4.48043 7.10536 4.73478 7 5 7H11C11.5523 7 12 6.55228 12 6C12 5.44772 11.5523 5 11 5H5C4.20435 5 3.44129 5.31607 2.87868 5.87868C2.31607 6.44129 2 7.20435 2 8V19C2 19.7957 2.31607 20.5587 2.87868 21.1213C3.44129 21.6839 4.20435 22 5 22H16C16.7957 22 17.5587 21.6839 18.1213 21.1213C18.6839 20.5587 19 19.7957 19 19V13C19 12.4477 18.5523 12 18 12C17.4477 12 17 12.4477 17 13V19C17 19.2652 16.8946 19.5196 16.7071 19.7071C16.5196 19.8946 16.2652 20 16 20H5C4.73478 20 4.48043 19.8946 4.29289 19.7071C4.10536 19.5196 4 19.2652 4 19V8C4 7.73478 4.10536 7.48043 4.29289 7.29289Z"
            fill="white"
            style="mix-blend-mode:difference"
          />
          <path
            d="M4.29289 7.29289C4.48043 7.10536 4.73478 7 5 7H11C11.5523 7 12 6.55228 12 6C12 5.44772 11.5523 5 11 5H5C4.20435 5 3.44129 5.31607 2.87868 5.87868C2.31607 6.44129 2 7.20435 2 8V19C2 19.7957 2.31607 20.5587 2.87868 21.1213C3.44129 21.6839 4.20435 22 5 22H16C16.7957 22 17.5587 21.6839 18.1213 21.1213C18.6839 20.5587 19 19.7957 19 19V13C19 12.4477 18.5523 12 18 12C17.4477 12 17 12.4477 17 13V19C17 19.2652 16.8946 19.5196 16.7071 19.7071C16.5196 19.8946 16.2652 20 16 20H5C4.73478 20 4.48043 19.8946 4.29289 19.7071C4.10536 19.5196 4 19.2652 4 19V8C4 7.73478 4.10536 7.48043 4.29289 7.29289Z"
            fill="white"
            style="mix-blend-mode:saturation"
          />
          <path
            d="M4.29289 7.29289C4.48043 7.10536 4.73478 7 5 7H11C11.5523 7 12 6.55228 12 6C12 5.44772 11.5523 5 11 5H5C4.20435 5 3.44129 5.31607 2.87868 5.87868C2.31607 6.44129 2 7.20435 2 8V19C2 19.7957 2.31607 20.5587 2.87868 21.1213C3.44129 21.6839 4.20435 22 5 22H16C16.7957 22 17.5587 21.6839 18.1213 21.1213C18.6839 20.5587 19 19.7957 19 19V13C19 12.4477 18.5523 12 18 12C17.4477 12 17 12.4477 17 13V19C17 19.2652 16.8946 19.5196 16.7071 19.7071C16.5196 19.8946 16.2652 20 16 20H5C4.73478 20 4.48043 19.8946 4.29289 19.7071C4.10536 19.5196 4 19.2652 4 19V8C4 7.73478 4.10536 7.48043 4.29289 7.29289Z"
            fill="white"
            style="mix-blend-mode:overlay"
          />
          <path
            d="M4.29289 7.29289C4.48043 7.10536 4.73478 7 5 7H11C11.5523 7 12 6.55228 12 6C12 5.44772 11.5523 5 11 5H5C4.20435 5 3.44129 5.31607 2.87868 5.87868C2.31607 6.44129 2 7.20435 2 8V19C2 19.7957 2.31607 20.5587 2.87868 21.1213C3.44129 21.6839 4.20435 22 5 22H16C16.7957 22 17.5587 21.6839 18.1213 21.1213C18.6839 20.5587 19 19.7957 19 19V13C19 12.4477 18.5523 12 18 12C17.4477 12 17 12.4477 17 13V19C17 19.2652 16.8946 19.5196 16.7071 19.7071C16.5196 19.8946 16.2652 20 16 20H5C4.73478 20 4.48043 19.8946 4.29289 19.7071C4.10536 19.5196 4 19.2652 4 19V8C4 7.73478 4.10536 7.48043 4.29289 7.29289Z"
            fill="black"
            style="mix-blend-mode:overlay"
          />
          <path
            d="M4.29289 7.29289C4.48043 7.10536 4.73478 7 5 7H11C11.5523 7 12 6.55228 12 6C12 5.44772 11.5523 5 11 5H5C4.20435 5 3.44129 5.31607 2.87868 5.87868C2.31607 6.44129 2 7.20435 2 8V19C2 19.7957 2.31607 20.5587 2.87868 21.1213C3.44129 21.6839 4.20435 22 5 22H16C16.7957 22 17.5587 21.6839 18.1213 21.1213C18.6839 20.5587 19 19.7957 19 19V13C19 12.4477 18.5523 12 18 12C17.4477 12 17 12.4477 17 13V19C17 19.2652 16.8946 19.5196 16.7071 19.7071C16.5196 19.8946 16.2652 20 16 20H5C4.73478 20 4.48043 19.8946 4.29289 19.7071C4.10536 19.5196 4 19.2652 4 19V8C4 7.73478 4.10536 7.48043 4.29289 7.29289Z"
            fill="white"
            style="mix-blend-mode:overlay"
          />
          <path
            d="M4.29289 7.29289C4.48043 7.10536 4.73478 7 5 7H11C11.5523 7 12 6.55228 12 6C12 5.44772 11.5523 5 11 5H5C4.20435 5 3.44129 5.31607 2.87868 5.87868C2.31607 6.44129 2 7.20435 2 8V19C2 19.7957 2.31607 20.5587 2.87868 21.1213C3.44129 21.6839 4.20435 22 5 22H16C16.7957 22 17.5587 21.6839 18.1213 21.1213C18.6839 20.5587 19 19.7957 19 19V13C19 12.4477 18.5523 12 18 12C17.4477 12 17 12.4477 17 13V19C17 19.2652 16.8946 19.5196 16.7071 19.7071C16.5196 19.8946 16.2652 20 16 20H5C4.73478 20 4.48043 19.8946 4.29289 19.7071C4.10536 19.5196 4 19.2652 4 19V8C4 7.73478 4.10536 7.48043 4.29289 7.29289Z"
            fill="black"
            style="mix-blend-mode:overlay"
          />
        </svg>
      );
    case "contact-support":
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.80002 11.9973C3.7974 13.1149 4.13954 14.1571 4.4277 15.035C4.48868 15.2207 4.54725 15.3991 4.59961 15.5693C4.66318 15.7759 4.62045 16.1328 4.27481 16.6637C4.08407 16.9567 3.86004 17.2249 3.61524 17.4449C3.01786 17.9678 2.83144 18.8334 3.16702 19.5571C3.50529 20.2867 4.28644 20.7034 5.08075 20.578L9.10437 19.9426C13.2151 21.6563 18.1025 19.8173 20.0884 15.848C21.7315 12.5912 21.0845 8.49231 18.4961 5.90393C15.897 3.30486 11.8351 2.65516 8.55188 4.31164C5.66695 5.75511 3.80202 8.7714 3.80002 11.9973ZM3.80002 11.9973C3.80002 11.997 3.80003 11.9966 3.80003 11.9963L4.80002 11.9988L3.80002 11.9983C3.80002 11.998 3.80002 11.9977 3.80002 11.9973Z"
            stroke="#FF0000"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            style="mix-blend-mode:saturation"
          />
          <path
            d="M3.80002 11.9973C3.7974 13.1149 4.13954 14.1571 4.4277 15.035C4.48868 15.2207 4.54725 15.3991 4.59961 15.5693C4.66318 15.7759 4.62045 16.1328 4.27481 16.6637C4.08407 16.9567 3.86004 17.2249 3.61524 17.4449C3.01786 17.9678 2.83144 18.8334 3.16702 19.5571C3.50529 20.2867 4.28644 20.7034 5.08075 20.578L9.10437 19.9426C13.2151 21.6563 18.1025 19.8173 20.0884 15.848C21.7315 12.5912 21.0845 8.49231 18.4961 5.90393C15.897 3.30486 11.8351 2.65516 8.55188 4.31164C5.66695 5.75511 3.80202 8.7714 3.80002 11.9973ZM3.80002 11.9973C3.80002 11.997 3.80003 11.9966 3.80003 11.9963L4.80002 11.9988L3.80002 11.9983C3.80002 11.998 3.80002 11.9977 3.80002 11.9973Z"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            style="mix-blend-mode:difference"
          />
          <path
            d="M3.80002 11.9973C3.7974 13.1149 4.13954 14.1571 4.4277 15.035C4.48868 15.2207 4.54725 15.3991 4.59961 15.5693C4.66318 15.7759 4.62045 16.1328 4.27481 16.6637C4.08407 16.9567 3.86004 17.2249 3.61524 17.4449C3.01786 17.9678 2.83144 18.8334 3.16702 19.5571C3.50529 20.2867 4.28644 20.7034 5.08075 20.578L9.10437 19.9426C13.2151 21.6563 18.1025 19.8173 20.0884 15.848C21.7315 12.5912 21.0845 8.49231 18.4961 5.90393C15.897 3.30486 11.8351 2.65516 8.55188 4.31164C5.66695 5.75511 3.80202 8.7714 3.80002 11.9973ZM3.80002 11.9973C3.80002 11.997 3.80003 11.9966 3.80003 11.9963L4.80002 11.9988L3.80002 11.9983C3.80002 11.998 3.80002 11.9977 3.80002 11.9973Z"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            style="mix-blend-mode:saturation"
          />
          <path
            d="M3.80002 11.9973C3.7974 13.1149 4.13954 14.1571 4.4277 15.035C4.48868 15.2207 4.54725 15.3991 4.59961 15.5693C4.66318 15.7759 4.62045 16.1328 4.27481 16.6637C4.08407 16.9567 3.86004 17.2249 3.61524 17.4449C3.01786 17.9678 2.83144 18.8334 3.16702 19.5571C3.50529 20.2867 4.28644 20.7034 5.08075 20.578L9.10437 19.9426C13.2151 21.6563 18.1025 19.8173 20.0884 15.848C21.7315 12.5912 21.0845 8.49231 18.4961 5.90393C15.897 3.30486 11.8351 2.65516 8.55188 4.31164C5.66695 5.75511 3.80202 8.7714 3.80002 11.9973ZM3.80002 11.9973C3.80002 11.997 3.80003 11.9966 3.80003 11.9963L4.80002 11.9988L3.80002 11.9983C3.80002 11.998 3.80002 11.9977 3.80002 11.9973Z"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            style="mix-blend-mode:overlay"
          />
          <path
            d="M3.80002 11.9973C3.7974 13.1149 4.13954 14.1571 4.4277 15.035C4.48868 15.2207 4.54725 15.3991 4.59961 15.5693C4.66318 15.7759 4.62045 16.1328 4.27481 16.6637C4.08407 16.9567 3.86004 17.2249 3.61524 17.4449C3.01786 17.9678 2.83144 18.8334 3.16702 19.5571C3.50529 20.2867 4.28644 20.7034 5.08075 20.578L9.10437 19.9426C13.2151 21.6563 18.1025 19.8173 20.0884 15.848C21.7315 12.5912 21.0845 8.49231 18.4961 5.90393C15.897 3.30486 11.8351 2.65516 8.55188 4.31164C5.66695 5.75511 3.80202 8.7714 3.80002 11.9973ZM3.80002 11.9973C3.80002 11.997 3.80003 11.9966 3.80003 11.9963L4.80002 11.9988L3.80002 11.9983C3.80002 11.998 3.80002 11.9977 3.80002 11.9973Z"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            style="mix-blend-mode:overlay"
          />
          <path
            d="M3.80002 11.9973C3.7974 13.1149 4.13954 14.1571 4.4277 15.035C4.48868 15.2207 4.54725 15.3991 4.59961 15.5693C4.66318 15.7759 4.62045 16.1328 4.27481 16.6637C4.08407 16.9567 3.86004 17.2249 3.61524 17.4449C3.01786 17.9678 2.83144 18.8334 3.16702 19.5571C3.50529 20.2867 4.28644 20.7034 5.08075 20.578L9.10437 19.9426C13.2151 21.6563 18.1025 19.8173 20.0884 15.848C21.7315 12.5912 21.0845 8.49231 18.4961 5.90393C15.897 3.30486 11.8351 2.65516 8.55188 4.31164C5.66695 5.75511 3.80202 8.7714 3.80002 11.9973ZM3.80002 11.9973C3.80002 11.997 3.80003 11.9966 3.80003 11.9963L4.80002 11.9988L3.80002 11.9983C3.80002 11.998 3.80002 11.9977 3.80002 11.9973Z"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            style="mix-blend-mode:overlay"
          />
          <path
            d="M3.80002 11.9973C3.7974 13.1149 4.13954 14.1571 4.4277 15.035C4.48868 15.2207 4.54725 15.3991 4.59961 15.5693C4.66318 15.7759 4.62045 16.1328 4.27481 16.6637C4.08407 16.9567 3.86004 17.2249 3.61524 17.4449C3.01786 17.9678 2.83144 18.8334 3.16702 19.5571C3.50529 20.2867 4.28644 20.7034 5.08075 20.578L9.10437 19.9426C13.2151 21.6563 18.1025 19.8173 20.0884 15.848C21.7315 12.5912 21.0845 8.49231 18.4961 5.90393C15.897 3.30486 11.8351 2.65516 8.55188 4.31164C5.66695 5.75511 3.80202 8.7714 3.80002 11.9973ZM3.80002 11.9973C3.80002 11.997 3.80003 11.9966 3.80003 11.9963L4.80002 11.9988L3.80002 11.9983C3.80002 11.998 3.80002 11.9977 3.80002 11.9973Z"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            style="mix-blend-mode:overlay"
          />
        </svg>
      );
    case "help-center":
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 2.25C10.0716 2.25 8.18657 2.82183 6.58319 3.89317C4.97982 4.96451 3.73013 6.48726 2.99218 8.26884C2.25422 10.0504 2.06114 12.0108 2.43735 13.9021C2.81355 15.7934 3.74215 17.5307 5.10571 18.8943C6.46928 20.2579 8.20656 21.1865 10.0979 21.5627C11.9892 21.9389 13.9496 21.7458 15.7312 21.0078C17.5127 20.2699 19.0355 19.0202 20.1068 17.4168C21.1782 15.8134 21.75 13.9284 21.75 12C21.7473 9.41498 20.7192 6.93661 18.8913 5.10872C17.0634 3.28084 14.585 2.25273 12 2.25ZM15.6656 14.6053C16.2083 13.8449 16.4999 12.9341 16.4999 12C16.4999 11.0659 16.2083 10.155 15.6656 9.39469L18.3375 6.72375C19.5732 8.20427 20.2501 10.0715 20.2501 12C20.2501 13.9285 19.5732 15.7957 18.3375 17.2762L15.6656 14.6053ZM9 12C9 11.4067 9.17595 10.8266 9.5056 10.3333C9.83524 9.83994 10.3038 9.45542 10.852 9.22836C11.4001 9.0013 12.0033 8.94189 12.5853 9.05764C13.1672 9.1734 13.7018 9.45912 14.1213 9.87868C14.5409 10.2982 14.8266 10.8328 14.9424 11.4147C15.0581 11.9967 14.9987 12.5999 14.7716 13.1481C14.5446 13.6962 14.1601 14.1648 13.6667 14.4944C13.1734 14.8241 12.5933 15 12 15C11.2044 15 10.4413 14.6839 9.87868 14.1213C9.31607 13.5587 9 12.7956 9 12ZM17.2763 5.6625L14.6053 8.33438C13.845 7.79174 12.9341 7.50007 12 7.50007C11.0659 7.50007 10.1551 7.79174 9.39469 8.33438L6.72375 5.6625C8.20427 4.42678 10.0715 3.74991 12 3.74991C13.9285 3.74991 15.7957 4.42678 17.2763 5.6625ZM5.6625 6.72375L8.33438 9.39469C7.79175 10.155 7.50007 11.0659 7.50007 12C7.50007 12.9341 7.79175 13.8449 8.33438 14.6053L5.6625 17.2762C4.42678 15.7957 3.74991 13.9285 3.74991 12C3.74991 10.0715 4.42678 8.20427 5.6625 6.72375ZM6.72375 18.3375L9.39469 15.6656C10.1551 16.2083 11.0659 16.4999 12 16.4999C12.9341 16.4999 13.845 16.2083 14.6053 15.6656L17.2763 18.3375C15.7957 19.5732 13.9285 20.2501 12 20.2501C10.0715 20.2501 8.20427 19.5732 6.72375 18.3375Z"
            fill="white"
          />
        </svg>
      );
    case "terms-conditions":
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M20.9489 3.75667C20.9381 3.57319 20.8604 3.40003 20.7305 3.27007C20.6005 3.1401 20.4273 3.06239 20.2439 3.05167C13.1067 2.63261 7.38979 4.78136 4.95229 8.81261C4.10738 10.1916 3.69103 11.7904 3.75604 13.4064C3.80948 14.8989 4.24448 16.4064 5.04885 17.8923L3.21979 19.7204C3.07906 19.8612 3 20.052 3 20.251C3 20.4501 3.07906 20.6409 3.21979 20.7817C3.36052 20.9224 3.55139 21.0015 3.75042 21.0015C3.94944 21.0015 4.14031 20.9224 4.28104 20.7817L6.10917 18.9526C7.59417 19.756 9.1026 20.191 10.5942 20.2445C10.6985 20.2482 10.8026 20.2501 10.9064 20.2501C12.4174 20.2541 13.8997 19.838 15.1879 19.0482C19.2192 16.6107 21.3689 10.8948 20.9489 3.75667ZM14.4145 17.7657C12.2817 19.0576 9.75698 19.0782 7.22292 17.8379L15.532 9.5298C15.6017 9.46011 15.6569 9.37739 15.6947 9.28634C15.7324 9.1953 15.7518 9.09772 15.7518 8.99917C15.7518 8.90062 15.7324 8.80304 15.6947 8.712C15.6569 8.62095 15.6017 8.53823 15.532 8.46855C15.4623 8.39886 15.3796 8.34359 15.2885 8.30588C15.1975 8.26816 15.0999 8.24875 15.0014 8.24875C14.9028 8.24875 14.8052 8.26816 14.7142 8.30588C14.6231 8.34359 14.5404 8.39886 14.4707 8.46855L6.1626 16.7814C4.92604 14.2501 4.94385 11.7189 6.23479 9.5898C8.30573 6.17073 13.2285 4.29855 19.4826 4.52167C19.7067 10.771 17.8335 15.6948 14.4145 17.7657Z"
            fill="white"
          />
        </svg>
      );

    case "members":
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.9999 11.5238C14.6298 11.5238 16.7618 9.39183 16.7618 6.7619C16.7618 4.13198 14.6298 2 11.9999 2C9.36996 2 7.23798 4.13198 7.23798 6.7619C7.23798 9.39183 9.36996 11.5238 11.9999 11.5238ZM2.47607 19.1429C2.47607 15.7492 4.25109 12.7701 6.92336 11.0834C8.14616 12.5184 9.96674 13.4286 11.9999 13.4286C14.033 13.4286 15.8536 12.5184 17.0764 11.0834C19.7487 12.7701 21.5237 15.7492 21.5237 19.1429L21.5237 19.146C21.522 20.7225 20.2435 22 18.6665 22H5.33321C3.75526 22 2.47607 20.7208 2.47607 19.1429Z"
            fill="white"
          />
        </svg>
      );

    case "team-details":
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.25 2.5C4.00736 2.5 3 3.50736 3 4.75V8.75C3 9.99264 4.00736 11 5.25 11H9.25C10.4926 11 11.5 9.99264 11.5 8.75V4.75C11.5 3.50736 10.4926 2.5 9.25 2.5H5.25ZM4.5 4.75C4.5 4.33579 4.83579 4 5.25 4H9.25C9.66421 4 10 4.33579 10 4.75V8.75C10 9.16421 9.66421 9.5 9.25 9.5H5.25C4.83579 9.5 4.5 9.16421 4.5 8.75V4.75ZM15.25 2.5C14.0074 2.5 13 3.50736 13 4.75V8.75C13 9.99264 14.0074 11 15.25 11H19.25C20.4926 11 21.5 9.99264 21.5 8.75V4.75C21.5 3.50736 20.4926 2.5 19.25 2.5H15.25ZM14.5 4.75C14.5 4.33579 14.8358 4 15.25 4H19.25C19.6642 4 20 4.33579 20 4.75V8.75C20 9.16421 19.6642 9.5 19.25 9.5H15.25C14.8358 9.5 14.5 9.16421 14.5 8.75V4.75ZM3 14.75C3 13.5074 4.00736 12.5 5.25 12.5H9.25C10.4926 12.5 11.5 13.5074 11.5 14.75V18.75C11.5 19.9926 10.4926 21 9.25 21H5.25C4.00736 21 3 19.9926 3 18.75V14.75ZM5.25 14C4.83579 14 4.5 14.3358 4.5 14.75V18.75C4.5 19.1642 4.83579 19.5 5.25 19.5H9.25C9.66421 19.5 10 19.1642 10 18.75V14.75C10 14.3358 9.66421 14 9.25 14H5.25ZM15.25 12.5C14.0074 12.5 13 13.5074 13 14.75V18.75C13 19.9926 14.0074 21 15.25 21H19.25C20.4926 21 21.5 19.9926 21.5 18.75V14.75C21.5 13.5074 20.4926 12.5 19.25 12.5H15.25ZM14.5 14.75C14.5 14.3358 14.8358 14 15.25 14H19.25C19.6642 14 20 14.3358 20 14.75V18.75C20 19.1642 19.6642 19.5 19.25 19.5H15.25C14.8358 19.5 14.5 19.1642 14.5 18.75V14.75Z"
            fill="white"
          />
        </svg>
      );

    case "plans-and-payment":
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.5589 2.39345C11.8219 2.20218 12.1782 2.20218 12.4412 2.39345L20.6912 8.39345C20.9539 8.58452 21.0637 8.923 20.9633 9.23193C20.8628 9.54086 20.5749 9.75 20.2501 9.75H18.75V15.75H19.5C19.9142 15.75 20.25 16.0858 20.25 16.5C20.25 16.9142 19.9142 17.25 19.5 17.25H18H14.25H9.75H6H4.5C4.08579 17.25 3.75 16.9142 3.75 16.5C3.75 16.0858 4.08579 15.75 4.5 15.75H5.25V9.75H3.75005C3.4252 9.75 3.13727 9.54086 3.03681 9.23193C2.93635 8.923 3.0462 8.58452 3.30892 8.39345L11.5589 2.39345ZM17.25 9.75V15.75H15V9.75H17.25ZM13.5 9.75V15.75H10.5V9.75H13.5ZM9 9.75V15.75H6.75V9.75H9ZM17.9437 8.25H14.25H9.75H6.05644L12.0001 3.92737L17.9437 8.25ZM3 18.75C2.58579 18.75 2.25 19.0858 2.25 19.5C2.25 19.9142 2.58579 20.25 3 20.25H21C21.4142 20.25 21.75 19.9142 21.75 19.5C21.75 19.0858 21.4142 18.75 21 18.75H3Z"
            fill="white"
          />
        </svg>
      );

    default:
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M19.5 3.75H4.5C4.10218 3.75 3.72064 3.90804 3.43934 4.18934C3.15804 4.47064 3 4.85218 3 5.25V10.7606C3 19.1616 10.1081 21.9487 11.5312 22.4222C11.8352 22.5256 12.1648 22.5256 12.4688 22.4222C13.8938 21.9487 21 19.1616 21 10.7606V5.25C21 4.85218 20.842 4.47064 20.5607 4.18934C20.2794 3.90804 19.8978 3.75 19.5 3.75ZM19.5 10.7616C19.5 18.1134 13.2797 20.5697 12 20.9972C10.7316 20.5744 4.5 18.12 4.5 10.7616V5.25H19.5V10.7616ZM7.71937 13.2806C7.57864 13.1399 7.49958 12.949 7.49958 12.75C7.49958 12.551 7.57864 12.3601 7.71937 12.2194C7.86011 12.0786 8.05098 11.9996 8.25 11.9996C8.44902 11.9996 8.63989 12.0786 8.78063 12.2194L10.5 13.9387L15.2194 9.21937C15.2891 9.14969 15.3718 9.09442 15.4628 9.0567C15.5539 9.01899 15.6515 8.99958 15.75 8.99958C15.8485 8.99958 15.9461 9.01899 16.0372 9.0567C16.1282 9.09442 16.2109 9.14969 16.2806 9.21937C16.3503 9.28906 16.4056 9.37178 16.4433 9.46283C16.481 9.55387 16.5004 9.65145 16.5004 9.75C16.5004 9.84855 16.481 9.94613 16.4433 10.0372C16.4056 10.1282 16.3503 10.2109 16.2806 10.2806L11.0306 15.5306C10.961 15.6004 10.8783 15.6557 10.7872 15.6934C10.6962 15.7312 10.5986 15.7506 10.5 15.7506C10.4014 15.7506 10.3038 15.7312 10.2128 15.6934C10.1217 15.6557 10.039 15.6004 9.96937 15.5306L7.71937 13.2806Z"
            fill="white"
          />
        </svg>
      );
  }
};
