import { FunctionalComponent, h } from "preact";
import { styled } from "linaria/react";
import mq from "style/breakpoints";
import Colors from "style/colors";
import Button from "components/shared/Button";
import Icon, { IconTypes } from "components/shared/Icon";
import MemberListTable from "./MemberListTable";
import { useState } from "preact/hooks";
import useSubscriptionGroup from "hooks/subscriptionGroupHooks";
import { useWindowSize } from "hooks/windowHook";
import MemberModalContent from "components/settings/MemberModalContent";
import dayjs from "dayjs";
import { orgPlanPrice } from "util/subscriptionUtils";
import { SubscriptionGroupInvite } from "models/subscriptionGroup";

const Container = styled.div`
  width: 100%;
  background: #ffffff;
  padding: 8px 16px 69px 16px;

  ${mq.minMd} {
    max-width: 1280px;
  }

  ${mq.minSm} {
    max-width: 1280px;
    padding: 272px 0 0 0;
    background: transparent;
  }

  @media (min-width: 1500px) {
    max-width: 1440px;
  }

  @media (max-width: 1300px) {
    max-width: 1000px;
  }

  @media (max-width: 1000px) {
    max-width: 850px;
  }
`;

const MemberBreakdown = styled.div`
  border-radius: 16px;
  border: 1px solid ${Colors.bgGrey2};
  background: ${Colors.bgLight2};
  padding: 32px;

  ${mq.minSm} {
  }
`;

const Header = styled.header`
  width: 100%;
  display: flex;
  justify-content: space-between;

  h1 {
    color: ${Colors.txtDark};
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    text-transform: capitalize;
  }

  > div {
    display: flex;
    gap: 16px;
  }
`;

const InviteMembersContainer = styled.div`
  button {
    border-radius: 99px;
    background: ${Colors.bgBlue0};
    height: 32px;
    padding: 8px 12px;

    :hover:active {
      background: ${Colors.bgBlue0};
    }

    span {
      color: #ffffff;
      font-size: 14px;
      font-weight: 600;
      line-height: 16px;
    }
  }
`;

const UploadContainer = styled.div`
  button {
    border-radius: 99px;
    border: 1px solid ${Colors.bgDark0};
    background: ${Colors.bgLight2};
    height: 32px;
    padding: 8px 12px;

    :hover:active {
      background: ${Colors.bgLight2};
    }

    span {
      color: ${Colors.txtDark1};
      font-size: 14px;
      font-weight: 600;
      line-height: 16px;
    }
  }
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  border-top: 1px dashed ${Colors.bgGrey2};
  margin: 32px 0;
`;

const MemberBreakdownContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 264px;
  gap: 32px;

  > div {
    border-radius: 8px;
    background: ${Colors.bgLight3};
    padding: 32px;
    width: 100%;
    height: 128px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    h2 {
      color: ${Colors.txtDark};
      font-family: "Neusa Next Pro";
      font-size: 40px;
      font-weight: 700;
      line-height: 48px;
      text-transform: uppercase;
      margin-bottom: 8px;
    }

    > span {
      color: ${Colors.txtGrey3};
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      text-transform: capitalize;
    }
  }

  @media (max-width: 1300px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 500px) {
    display: flex;
    flex-direction: column;
  }
`;

const ActiveMembersWrapper = styled.div`
  h2 {
    display: flex;
    align-items: center;
    gap: 1px;

    > div {
      border-radius: 99px;
      background: ${Colors.bgBlue0};
      padding: 2px 6px;
      color: ${Colors.txtLight};
      font-size: 12px;
      font-weight: 600;
      line-height: 16px;
      margin-bottom: 6px;
      margin-left: 6px;
      display: flex;
      align-items: center;
    }

    .icon-Arrow--Right {
      display: flex;
      :before {
        font-size: 8px;
      }
    }
  }

  @media (max-width: 1000px) {
    min-width: 100%;
  }
`;

const PendingWrapper = styled.div`
  @media (max-width: 1000px) {
    min-width: 100%;
  }
`;

const MonthWrapper = styled.div`
  h2 {
    font-size: 24px !important;
  }

  ${mq.minSm} {
    align-items: flex-end !important;
  }
`;

const InviteButtonMobile = styled.footer`
  grid-column: 1 / -1;

  > button {
    border-radius: 100px;
    background: #0d41e1;
    width: 100%;
    height: 44px;

    span {
      color: #ffffff;
      font-size: 18px;
      font-weight: 600;
      line-height: 24px;
    }
  }
`;

interface MembersSectionProps {
  setIsVisibleOverlay?: () => void;
  memberLimit: number;
}

const MembersSection: FunctionalComponent<MembersSectionProps> = ({
  setIsVisibleOverlay,
  memberLimit,
}: MembersSectionProps): JSX.Element | null => {
  const { ArrowRight } = IconTypes;
  const { width } = useWindowSize();
  const [invitationModal, setInvitationModal] = useState(false);
  const [isLimitMember, setIsLimitMember] = useState(false);
  const [uploadCSV, setUploadCSV] = useState(false);
  const { getSubscriptionGroupInvites, getSubscriptionGroupAdmin } =
    useSubscriptionGroup();
  const [refetch, setRefetch] = useState(false);
  const subscriptionGroupInvites = getSubscriptionGroupInvites(refetch);
  const activeMembers = subscriptionGroupInvites?.activeCount || 0;
  const pending = subscriptionGroupInvites?.pendingCount || 0;
  const billingCount = subscriptionGroupInvites?.billingCount || 0;
  const thirtyDaysAgo = dayjs().subtract(30, "day");
  const plan = getSubscriptionGroupAdmin(
    subscriptionGroupInvites as SubscriptionGroupInvite
  )?.plan;

  const renderInviteButton = () => (
    <Button
      size="S"
      text="Invite Members"
      handleClick={() => {
        if (
          subscriptionGroupInvites &&
          subscriptionGroupInvites?.members.data?.length >= memberLimit
        ) {
          setIsLimitMember(true);
          setIsVisibleOverlay?.();
        } else {
          setInvitationModal(true);
          setIsVisibleOverlay?.();
        }
      }}
    />
  );

  const getTrend = () => {
    const trend =
      subscriptionGroupInvites?.members?.data?.filter(
        (member) =>
          member.status === "active" &&
          dayjs(member?.user?.createdAt).isAfter(thirtyDaysAgo) &&
          dayjs(member?.user?.createdAt).isBefore(dayjs())
      ) || [];

    return trend?.length;
  };

  return (
    <Container>
      <MemberBreakdown>
        <Header>
          <h1>Member Breakdown</h1>
          {width > 768 && (
            <div>
              <InviteMembersContainer>
                {renderInviteButton()}
              </InviteMembersContainer>
              <UploadContainer>
                <Button
                  size="S"
                  text="Upload CSV"
                  handleClick={() => {
                    if (
                      subscriptionGroupInvites &&
                      subscriptionGroupInvites?.members.data?.length >=
                        memberLimit
                    ) {
                      setIsLimitMember(true);
                      setIsVisibleOverlay?.();
                    } else {
                      setUploadCSV(true);
                      setIsVisibleOverlay?.();
                    }
                  }}
                />
              </UploadContainer>
            </div>
          )}
        </Header>
        <Divider />
        <MemberBreakdownContent>
          <ActiveMembersWrapper>
            <h2>
              {activeMembers}
              {getTrend() > 0 && (
                <div>
                  {`+${getTrend()}`}
                  <div
                    style={{
                      transform: `rotate(-45deg)`,
                    }}
                  >
                    <Icon name={ArrowRight} size="S" />
                  </div>
                </div>
              )}
            </h2>
            <span>Active Members</span>
          </ActiveMembersWrapper>
          <PendingWrapper>
            <h2>{pending}</h2>
            <span>Pending</span>
          </PendingWrapper>
          <MonthWrapper>
            <h2>
              {"$"}
              {plan ? orgPlanPrice(billingCount, plan) : "0.00"}
            </h2>
            <span>Per {plan?.planIntervalUnit || ""}</span>
          </MonthWrapper>
          {width <= 768 && (
            <InviteButtonMobile>{renderInviteButton()}</InviteButtonMobile>
          )}
        </MemberBreakdownContent>
      </MemberBreakdown>
      {width > 768 && (
        <MemberListTable
          invitationModal={invitationModal}
          isLimitMember={isLimitMember}
          uploadCSV={uploadCSV}
          setInvitationModal={setInvitationModal}
          subscriptionGroupInvites={subscriptionGroupInvites}
          setRefetch={() => setRefetch(!refetch)}
          onClose={() => {
            setInvitationModal(false);
            setIsLimitMember(false);
            setUploadCSV(false);
          }}
          memberLimit={memberLimit}
        />
      )}
      {(invitationModal || isLimitMember) && width <= 768 && (
        <MemberModalContent
          onClose={() => {
            setInvitationModal(false);
            setIsLimitMember(false);
            setIsVisibleOverlay?.();
          }}
          type={isLimitMember ? "limit-member-modal" : "member-modal"}
          memberLimit={memberLimit}
        />
      )}
    </Container>
  );
};

export default MembersSection;
